import './DevolucaoIG.css';
import icon2 from './imgs/Blue.png';
import icon3 from './imgs/icon3unselect.png';
import icon4 from './imgs/icon4unselect.png';
import iconCheck from './imgs/iconOk.png';
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import AlertBox from '../../AlertBox'


const DevolucaoIG = ({ itensSelecionados, notaFiscal }) => {
    const [mobile, setMobile] = useState(window.innerWidth <= 850);

    useEffect(() => {
        function handleResize() {
            setMobile(window.innerWidth <= 850);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (mobile === true) {
            document.documentElement.classList.add('no-scroll');
        } else {
            document.documentElement.classList.remove('no-scroll');
        }
    }, [mobile]);
    const navigate = useNavigate();
    var urlCompleto = window.location.href;
    urlCompleto = urlCompleto.replace("/motivoDevolucao", '');
    const [indiceItemSelecionado, setIndiceItemSelecionado] = useState(0);
    const [motivo, setMotivo] = useState('');
    const [alertBoxVisivel, setalertBoxVisivel] = useState(false);
    const [itensSelecionadosComMotivo, setItensSelecionadosComMotivo] = useState([]);

    useEffect(() => {
        setItensSelecionadosComMotivo(itensSelecionados.map(item => ({ ...item, motivo: '' })));
    }, [itensSelecionados]);

    const handleMotivoChange = (event) => {
        const updatedItens = [...itensSelecionadosComMotivo];
        updatedItens[indiceItemSelecionado].motivo = event.target.value;
        setMotivo(event.target.value);
    };

    const handleItemClick = (index) => {
        setIndiceItemSelecionado(index);
        const selectedMotivo = itensSelecionadosComMotivo[index]?.motivo || '';
        setMotivo(selectedMotivo);
    };
    const proximaPagina = () => {
        const allItensHaveMotivo = itensSelecionadosComMotivo.every(item => item.motivo !== '');

        if (allItensHaveMotivo) {
            setalertBoxVisivel(false);
            navigate('enviarComprovante', { state: { itensSelecionadosComMotivo, notaFiscal } })
        } else {
            setalertBoxVisivel(true);
            setTimeout(() => {
                setalertBoxVisivel(false);
            }, 5000);
        }
    }

    return (
        <div className='devolucaoDivMaior' style={{ display: "flex", justifyContent: "center" }}>
            <AlertBox visivel={alertBoxVisivel} texto='Atenção! Você deve escolher o motivo para todos os itens para continuar' />
            {mobile ? (
                <div style={{ display: 'flex', gap: '1em', flexDirection: 'column', marginTop: '1em', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginLeft: '5%' }}>
                        <div className='indicacaoDeEtapaAtualNumeroMobile'>2</div>
                        <label className='labelDoIndicacaoDeEtapaAtualNumeroMobile'>Informações gerais dos itens</label>
                    </div>
                    <div className='barraMaiorIndicacaoMobile'>
                        <div style={{ width: '40%' }} className='barraMenorIndicacaoMobile' />
                    </div>
                </div>
            ) : null}
            <div className='whiteBox'>
                <h1 className='tituloDaWhiteBoxMobile'>
                    Novo Protocolo de Devolução
                </h1>
                {!mobile ? (
                    <div>
                        <div className='progressBar'>
                            <span className='progressFill'></span>
                        </div>
                        <div className='progressStage'>
                            <div className='stages'>
                                <img src={iconCheck} alt='' />
                                <h2>Seleção de Itens</h2>
                            </div>

                            <div className='stages'>
                                <h2 style={{ color: 'rgb(255, 255, 255)', zIndex: '2', bottom: '0.9px', left: '25.3px', position: 'relative' }}>2</h2>
                                <img src={icon2} alt='' />
                                <h2 style={{ fontWeight: 700, fontSize: 14, color: "#041E42" }}>Informações gerais</h2>
                            </div>

                            <div className='stages'>
                                <img src={icon3} alt='' />
                                <h2>Documentação</h2>
                            </div>

                            <div className='stages'>
                                <img src={icon4} alt='' />
                                <h2>Validação</h2>
                            </div>

                        </div>
                    </div>
                ) : null}
                <div className='anotherBox'>
                    <div className='leftSide'>
                        <div>
                            <label style={{ marginLeft: '2lh' }}>Responda sobre o item selecionado:</label>
                            <ul>
                                {itensSelecionadosComMotivo.map((item, index) => (
                                    <div
                                        className={`caixaDoItemSelecionado ${index === indiceItemSelecionado ? 'itemSelecionado' : ''}`}
                                        name={index}
                                        key={index}
                                        style={{ display: 'flex', flexDirection: 'column' }}
                                        onClick={() => handleItemClick(index)}>
                                        <li>
                                            <div className='quantidadeTexto'>
                                                Quantidade selecionada: {item.quantidadeSelecionada}
                                            </div>
                                            <div className='idDaPecaTexto'>
                                                {item.item.codigoDaPeca}
                                            </div>
                                        </li>
                                    </div>
                                ))}
                            </ul>
                        </div>
                    </div>
                    <hr className="verticalLineBox" />
                    <div className='rigthSide'>
                        <h1>
                            Qual o motivo da solicitação?
                        </h1>
                        <div className='selectReason'>
                            <label htmlFor='Reason' className='selectLabel'>
                                <select id="Reason" value={motivo} onChange={handleMotivoChange} className="customSelect">
                                    <option disabled value="">Selecionar motivo</option>
                                    <option value="Cliente_final_desistiu_da_compra">Cliente final desistiu da compra</option>
                                    <option value="Cliente_nao_usa_a_marca">Cliente não usa a marca</option>
                                    <option value="Cliente_pediu_modelo_errado">Cliente pediu modelo errado</option>
                                    <option value="Troca_de_medida">Troca de medida</option>
                                    <option value="Troca_de_modelo">Troca de modelo</option>
                                    <option value="Vendedor_enviou_peca_errada">Vendedor enviou peça errada</option>
                                </select>
                            </label>
                        </div>
                    </div>
                </div>
                {!mobile ? (<div className='divBackFoward'>
                    <Link to={urlCompleto} style={{ width: 'fit-content', height: 'fit-content' }}>
                        <div className='buttonBack' style={{ cursor: 'pointer' }}>
                            <h1>❮</h1>
                            <h1>Voltar</h1>
                        </div>
                    </Link>
                    <div className='buttonFoward' onClick={proximaPagina} style={{ cursor: 'pointer' }}>
                        <h1>Prosseguir</h1>
                        <h1>❯</h1>
                    </div>
                </div>) : (
                    <div className='botoesInferiores'>
                        <Link to={urlCompleto} style={{ width: 'fit-content', height: 'fit-content' }}>
                            <div className='botaoCancelar'><label>❮ </label><label>Voltar</label></div>
                        </Link>
                        <div onClick={proximaPagina} style={{ width: 'fit-content', height: 'fit-content', cursor: 'pointer' }}>
                            <div className='botaoProsseguir'>
                                <label style={{ cursor: 'pointer' }}>Prosseguir</label>
                                <label style={{ cursor: 'pointer' }}>❯</label>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div >
    )
}

export default DevolucaoIG;