import React, { useState, useEffect, useRef } from 'react';
import './Header.css';
import { Link, useNavigate } from 'react-router-dom';
import iconCall from './img/iconCall.svg';
import iconBell from './img/iconBell.svg';
import iconFacebook from './img/iconFacebook.svg';
import iconInstagram from './img/iconInstagram.svg';
import botaoX from './img/BotaoX.svg';
import logo from './img/logo.png';
import Cookies from 'js-cookie';
import iconMinhaConta1 from './img/iconMinhaConta1.svg';
import iconMinhaConta2 from './img/iconMinhaConta2.svg';
import { Dropdown } from 'primereact/dropdown';
import botaoHeaderMobile from './img/botaoHeaderMobile.svg';
import iconMinhaConta2Reversed from './img/iconMinhaConta2Reversed.svg';
import iconYoutube from './img/iconYoutubeHeader.svg';
import { renderIntoDocument } from 'react-dom/test-utils';
import ChatOctadesk from '../ChatOctadesk';
const Header = (props) => {
    const itemTemplate = (option) => {
        return (
            <div className="opcoesTextDeslogar">
                <label>{option.name}</label>
                {option.icon && <img src={option.icon} alt="" />}
            </div>
        );
    };
    const navigate = useNavigate();
    
    const [mobile, setMobile] = useState(window.innerWidth <= 850);
    useEffect(() => {
        function handleResize() {
            setMobile(window.innerWidth <= 850);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        resgatarQuantidadeDeNotificacoes();
    }, []);
    const resgatarQuantidadeDeNotificacoes = async () => {
        if (logado) {

            try {
                const token = Cookies.get('token');

                if (token) {
                    const headers = { 'Authorization': `Bearer ${token}` };
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/notificacoes/resgatarQuantidadeNotificacoesNaoLidas`, { headers });

                    if (!response.ok) {
                        throw new Error(`Erro na resposta do servidor: ${response.status} - ${response.statusText}`);
                    }

                    const data = await response.json();
                    setQuantidadeNotificacoesNaoLidas(data);
                }
            } catch (error) {
                console.error('Erro ao detalhar o protocolo de garantia', error);
            }
        }
    };
    const [dropdownDoHeaderMobileAberto, setDropdownDoHeaderMobileAberto] = useState(false);
    const [opcoesMinhaContaMobileAberto, setOpcoesMinhaContaMobileAberto] = useState(false);

    const [quantidadeNotificacoesNaoLidas, setQuantidadeNotificacoesNaoLidas] = useState(0);

    const { logado, admin } = props;
    const [texto, setTexto] = useState("ENTRE OU CADASTRE-SE");
    const [sino, setSino] = useState(false);
    const [link, setLink] = useState("");
    const [notificacoes, setNotificacoes] = useState([]);
    const [mostrarNotificacoes, setMostrarNotificacoes] = useState(false);
    const [posicaoBotao, setPosicaoBotao] = useState({ top: 0, left: 0 });
    const botaoRef = useRef(null);
    const [headerSelecionado, setHeaderSelecionado] = useState({ name: '', link: '' });
    const [opcoesHeader, setOpcoesHeader] = useState([
        { name: "Opções", icon: botaoX, link: '' },
        { name: 'Histórico de compras', link: '/historico' },
        { name: 'Meus protocolos', link: '/ListaDeProtocolos' },
        { name: 'Dados cadastrais', link: '/DadosCadastrais' },
        { name: 'Sair da conta', link: '/login' }
    ]);
    useEffect(() => {
        if (botaoRef.current) {
            const { top, left } = botaoRef.current.getBoundingClientRect();
            setPosicaoBotao({ top, left });
        }
    }, [mostrarNotificacoes]);
    useEffect(() => {
        if (headerSelecionado.link != '') {
            navigate(headerSelecionado.link)
        }
    }, [headerSelecionado]);

    useEffect(() => {
        if (mobile) {
            if (dropdownDoHeaderMobileAberto === true || mostrarNotificacoes === true) {
                window.scrollTo({ top: 0, behavior: 'instant' });
                document.documentElement.classList.add('no-scroll');
            } else {
                document.documentElement.classList.remove('no-scroll');
            }
        }
    }, [dropdownDoHeaderMobileAberto, mostrarNotificacoes, mobile]);

    const handleDropdownChange = (e) => {
        setHeaderSelecionado(e.value);
    };
    useEffect(() => {
    if (logado === true) {
        if(admin === true){
            setTexto(
                <div>
                    <label className='labelDoDropDown'><img src={iconMinhaConta1} alt=''></img>MINHA CONTA<img src={iconMinhaConta2} alt=''></img></label>
                    <Dropdown
                        onChange={handleDropdownChange}
                        options={[
                            { name: "Opções", icon: botaoX, link: '' },
                            { name: 'Histórico de compras', link: '/historico' },
                            { name: 'Meus protocolos', link: '/ListaDeProtocolos' },
                            { name: 'Dados cadastrais', link: '/DadosCadastrais' },
                            { name: 'Administrar site', link: '/Admin' },
                            { name: 'Sair da conta', link: '/login' }
                        ]}
                        optionLabel="name"
                        className="w-full md:w-14rem"
                        itemTemplate={itemTemplate}
                    />
                </div>
            );
        }else{
            setTexto(
                <div>
                    <label className='labelDoDropDown'><img src={iconMinhaConta1} alt=''></img>MINHA CONTA<img src={iconMinhaConta2} alt=''></img></label>
                    <Dropdown
                        onChange={handleDropdownChange}
                        options={[
                            { name: "Opções", icon: botaoX, link: '' },
                            { name: 'Histórico de compras', link: '/historico' },
                            { name: 'Meus protocolos', link: '/ListaDeProtocolos' },
                            { name: 'Dados cadastrais', link: '/DadosCadastrais' },
                            { name: 'Sair da conta', link: '/login' }
                        ]}
                        optionLabel="name"
                        className="w-full md:w-14rem"
                        itemTemplate={itemTemplate}
                    />
                </div>
            );
        }
        setSino(true);
        setLink("");
    } else {
        setTexto("ENTRE OU CADASTRE-SE");
        setSino(false);
        setLink("/login");
    }
    }, [logado, admin])
    const handleClickDropdownMobileChange = () => {
        setDropdownDoHeaderMobileAberto(!dropdownDoHeaderMobileAberto);
        setMostrarNotificacoes(false);
    }
    const formatarTextoTipoProtocoloNotificacao = (texto) => {
        let textoFormatado = "";

        switch (texto) {
            case "GARANTIA_EXPRESSA":
                textoFormatado = "Garantia Expressa"
                break;
            case "DEVOLUCAO":
                textoFormatado = "Devolução"
                break;
            case "GARANTIA":
                textoFormatado = "Garantia"
                break;
            default:
                textoFormatado = texto;
                break;
        }
        return textoFormatado
    }

    const handleSinoClick = async () => {
        resgatarQuantidadeDeNotificacoes();
        setMostrarNotificacoes(!mostrarNotificacoes);
        setDropdownDoHeaderMobileAberto(false);

        if (!mostrarNotificacoes) {
            try {
                const token = Cookies.get('token');

                if (token) {
                    const headers = new Headers({
                        'Authorization': `Bearer ${token}`,
                    });
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/notificacoes/listar`, { headers });
                    const data = await response.json();
                    data.sort((a, b) => new Date(b.dataNotificacao) - new Date(a.dataNotificacao));
                    setNotificacoes(data);
                }
            } catch (error) {
                console.error('Erro ao obter notificações:', error);
            }
        }
    };
    const formatarData = (data) => {
        const dataFormatada = new Date(data);
        const dia = dataFormatada.getDate().toString().padStart(2, '0');
        const mes = (dataFormatada.getMonth() + 1).toString().padStart(2, '0');
        const ano = dataFormatada.getFullYear();
        return `${dia}/${mes}/${ano}`;
    };
    return (
        <div>
            <ChatOctadesk/>
            <header className="headerHeader">
                <div className="top-sectionHeader">
                    <div className='top-sectionHeader-line'>
                        <div className='div1Header'>
                            <Link style={{display: 'flex', alignItems: 'center'}} target="_blank" rel="noopener noreferrer" to={"https://www.instagram.com/girospecas/"}><img src={iconInstagram} alt="Instagram" style={{marginLeft: '0em'}} /></Link>
                            <Link style={{display: 'flex', alignItems: 'center'}} target="_blank" rel="noopener noreferrer" to={"https://www.facebook.com/GirosPecasMotoresLtda/?locale=pt_BR"}><img src={iconFacebook} alt="Facebook" style={{marginRight: '1em', marginLeft: '1em'}}  /></Link>
                            <Link style={{display: 'flex', alignItems: 'center'}} target="_blank" rel="noopener noreferrer" to={"https://www.youtube.com/@girospecas"}><img src={iconYoutube} alt="Youtube" style={{marginRight: '1em'}} /></Link>
                            <p className='pHeader'>Siga nossas redes!</p>
                        </div>
                        <div className='div2Header'>
                            <img src={iconCall} alt="Icon Call" className='img3Header' />
                            <p className='pHeader'>
                                <span className="light-textHeader">Central de Atendimento:</span>
                                <span className="bold-textHeader"> 4003-8386</span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='parteBrancaHeader'>
                    <div className='organizarHeader'>
                        <Link to={"/"}><img src={logo} alt="Logo" className='imgLogoHeader' /></Link>
                        <ul className='listaHeader'>
                            <li className='letterHeader' style={{ display: mobile ? "none" : 'inline-block' }}><Link to={"/institucional"}>INSTITUCIONAL</Link></li>
                            <li className='letterHeader' style={{ display: mobile ? "none" : 'inline-block' }}><Link to={"/produtos"}>PRODUTOS</Link></li>
                            <li className='letterHeader' style={{ display: mobile ? "none" : 'inline-block' }}><Link to={"/conteudos"}>CONTEÚDOS</Link></li>
                            <li className='letterHeader' style={{ display: mobile ? "none" : 'inline-block' }}><Link to={"/trabalheConosco"}>TRABALHE CONOSCO</Link></li>
                            <li className='letterHeader' style={{ display: mobile ? "none" : 'inline-block' }}><Link to={"/contato"}>CONTATO</Link></li>
                            <li className='letterHeader' ref={botaoRef} onClick={handleSinoClick} style={{ display: sino ? 'inline' : 'none', background: 'none', border: 'none', cursor: 'pointer' }}>
                                <img src={iconBell} style={{ display: sino ? 'inline' : 'none', marginBottom: '-2%' }} alt='Imagem de um sino' />
                                <label style={{ display: quantidadeNotificacoesNaoLidas <= 0 ? 'none' : 'block' }} className='quantidadeNotificacoesNaoLidas'>{quantidadeNotificacoesNaoLidas}</label>
                            </li>
                            {!mobile ? (
                                <div className="notificacoesDiv" style={{ height: mostrarNotificacoes ? "100%" : "0", border: mostrarNotificacoes ? "1px solid #CFCFCF" : "none", top: posicaoBotao.top + 50, left: posicaoBotao.left - 95 }}>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', paddingLeft: '10px', paddingRight: '10px', borderBottom: "1px solid #CFCFCF" }}>
                                        <h3 className='notificacoesTitulo' style={{ paddingTop: "10px" }}>Notificações</h3>
                                        <button onClick={handleSinoClick} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
                                            <img alt='' src={botaoX} style={{ width: '10px', height: '10px' }}></img>
                                        </button>
                                    </div>
                                    <div style={{ overflow: 'hidden', maxHeight: '17.5em', overflowY: 'auto' }}>
                                        {notificacoes && notificacoes.length > 0 ? (
                                            notificacoes.map((notificacao, index) => (
                                                index < 5 ? (
                                                    <li key={index} style={{ borderBottom: index >= 4 || index >= (notificacoes.length - 1) ? "none" : "1px solid #CFCFCF" }} className={notificacao.lida === true ? "notificacaoItem" : "notificacaoItem notificacaoNaoLida"}>
                                                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em', justifyContent: notificacao.lida === true ? 'flex-end' : 'space-between' }}>
                                                            <p style={{ display: notificacao.lida === true ? 'none' : 'auto' }} className='textoNotificacaoNaoLida'>Não lida</p>
                                                            <p className='dataNot'>{formatarData(notificacao.dataNotificacao)}</p>
                                                        </div>
                                                        <h3 className={notificacao.lida === true ? "textoNot" : "textoNot textoNotNaoLida"} style={{ fontWeight: '900' }}>{"Protocolo de " + formatarTextoTipoProtocoloNotificacao(notificacao.tipoProtocolo) + " n° " + notificacao.protocoloId + ":"}</h3>
                                                        <p className={notificacao.lida === true ? "textoNot" : "textoNot textoNotNaoLida"}>{notificacao.texto}</p>
                                                    </li>
                                                ) : null
                                            ))
                                        ) : (
                                            <div style={{ display: 'flex', alignContent: 'center', textAlign: 'center', color: '#003594' }}>Você ainda não tem nenhuma notificação...</div>
                                        )}
                                    </div>
                                    <hr className='linhasNotificacoes' />
                                    <Link to={"/ListarNotificacoes"} style={{ display: 'fit-content' }}>
                                        {notificacoes && notificacoes.length > 0 ? (
                                            <div className='verTodasNotificações' style={{ paddingBottom: '10px' }}>ver todas notificações</div>
                                        ) : (
                                            <div className='verTodasNotificações' style={{ paddingBottom: '10px' }}>ir para página de notificações</div>
                                        )}
                                    </Link>
                                </div>
                            ) : null}

                            {!mobile ? (<li>
                                {link ? (
                                    <Link to={link} style={{ width: 'fit-content', height: 'fit-content' }}>
                                        <button className="blue-border-buttonHeader">
                                            {texto}
                                        </button>
                                    </Link>
                                ) : (
                                    <button className="blue-border-buttonHeader" onClick={() => setMostrarNotificacoes(false)}>
                                        {texto}
                                    </button>
                                )}
                            </li>) :
                                (<li>
                                    <img src={botaoHeaderMobile} onClick={handleClickDropdownMobileChange} alt=''></img>
                                </li>)}
                        </ul>
                    </div>
                </div>
            </header>
            <div style={{ display: mostrarNotificacoes || dropdownDoHeaderMobileAberto ? "flex" : "none" }} className='backgroundCinzaParaOsHeaders'></div>
            {mobile ? (
                <ul className='dropdownHeaderParaMobile' style={{ height: dropdownDoHeaderMobileAberto ? '90%' : '0' }}>
                    <li style={{ paddingTop: "1em" }}>
                        {link ? (
                            <Link to={link} style={{ width: 'fit-content', height: 'fit-content' }}>
                                <button className="blue-border-buttonHeader" style={{ width: '90%' }}>
                                    {texto}
                                </button>
                            </Link>
                        ) : (
                            <button className="blue-border-buttonHeader" style={{ width: '90%' }} onClick={() => setOpcoesMinhaContaMobileAberto(!opcoesMinhaContaMobileAberto)}>
                                <label className='labelDoDropDown'><img src={iconMinhaConta1} alt=''></img>MINHA CONTA<img src={opcoesMinhaContaMobileAberto ? iconMinhaConta2Reversed : iconMinhaConta2} alt=''></img></label>
                            </button>
                        )}
                    </li>
                    <li style={{ display: opcoesMinhaContaMobileAberto ? 'list-item' : 'none', color: '#003594' }} onClick={() => navigate("/historico")}>HISTÓRICO DE COMPRAS</li>
                    <li style={{ display: opcoesMinhaContaMobileAberto ? 'list-item' : 'none', color: '#003594' }} onClick={() => navigate("/ListaDeProtocolos")}>MEUS PROTOCOLOS</li>
                    <li style={{ display: opcoesMinhaContaMobileAberto ? 'list-item' : 'none', color: '#003594' }} onClick={() => navigate("/DadosCadastrais")}>DADOS CADASTRAIS</li>
                    <li style={{ display: opcoesMinhaContaMobileAberto && admin === true ? 'list-item' : 'none', color: '#003594' }} onClick={() => navigate("/Admin")}>ADMINISTRAR SITE</li>
                    <li style={{ display: opcoesMinhaContaMobileAberto ? 'list-item' : 'none', borderBottom: 'none', paddingBottom: '3em', color: '#D40000' }} onClick={() => navigate("/login")}>SAIR DA CONTA</li>
                    <li><Link to={"/institucional"}>INSTITUCIONAL</Link></li>
                    <li><Link to={"/produtos"}>PRODUTOS</Link></li>
                    <li><Link to={"/conteudos"}>CONTEÚDOS</Link></li>
                    <li><Link to={"/trabalheConosco"}>TRABALHE-CONOSCO</Link></li>
                    <li><Link to={"/contato"}>CONTATO</Link></li>
                </ul>
            ) : null}
            {mobile ? (
                <ul className='dropdownHeaderParaMobile' style={{ height: mostrarNotificacoes ? '86%' : '0', overflowY: 'auto', padding: '0', width: '75%' }}>
                    <li className='tituloNotificacoesMobile'>NOTIFICAÇÕES</li>
                    {notificacoes && notificacoes.length > 0 ? (
                        notificacoes.map((notificacao, index) => (
                            index <= 5 ? (
                                <li key={index} className={notificacao.lida === true ? "notificacaoItem" : "notificacaoItem notificacaoNaoLidaMobile"}>
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5em', justifyContent: notificacao.lida === true ? 'flex-end' : 'space-between' }}>
                                        <p style={{ display: notificacao.lida === true ? 'none' : 'auto' }} className='textoNotificacaoNaoLidaMobile'>Não lida</p>
                                        <p className='dataNot'>{formatarData(notificacao.dataNotificacao)}</p>
                                    </div>
                                    <h3 className={notificacao.lida === true ? "textoNot" : "textoNot textoNotNaoLidaMobile"} style={{ fontWeight: '900' }}>{"Protocolo de " + formatarTextoTipoProtocoloNotificacao(notificacao.tipoProtocolo) + " n° " + notificacao.protocoloId + ":"}</h3>
                                    <p className={notificacao.lida === true ? "textoNot" : "textoNot textoNotNaoLidaMobile"}>{notificacao.texto}</p>
                                </li>
                            ) : null
                        ))
                    ) : (
                        <div style={{ display: 'flex', alignContent: 'center', textAlign: 'center', color: '#003594' }}>Você ainda não tem nenhuma notificação...</div>
                    )}
                    <li style={{ borderBottom: 'none' }}>
                        <Link to={"/ListarNotificacoes"} className='botaoVerTodasNotificacoesMobile' style={{ display: 'fit-content' }}>
                            {notificacoes && notificacoes.length > 0 ? (
                                <div className='verTodasNotificações'>ver todas notificações</div>
                            ) : (
                                <div className='verTodasNotificações'>ir para página de notificações</div>
                            )}
                        </Link>
                    </li>
                </ul>
            ) : null}
        </div>
    );
}

export default Header;