import React, { useEffect, useState } from 'react';
import './ItensNotaFiscal.css';
import setaParaCima from '../SelecionarItensNotaFiscal/imgs/ImagemSetaParaCima.png';
import setaParaBaixo from '../SelecionarItensNotaFiscal/imgs/ImagemSetaParaBaixo.png';

const formatarValor = (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
const ItensNotaFiscal = ({ item, selecionar, quantidadeSelecionada, espacamento, onToggleItemSelecionado, onUpdateQuantidadeSelecionada }) => {
  const [isChecked, setChecked] = useState(false);
  const [quantidade, setQuantidade] = useState(item.quantidade);
  const [alterado, setAlterado] = useState(false);
  useEffect(() => {
    if (item.selecionado !== undefined && item.selecionado !== null) {
      if (item.selecionado === true) {
        setChecked(true);
      } else {
        setChecked(false);
      }
    }
    if(item.quantidadeSelecionada !== undefined && item.quantidadeSelecionada !== null) {
      setQuantidade(item.quantidadeSelecionada);
    }
  }, [item]);
  let paddingValorTotal = { paddingRight: "25vh" };
  let displayDoSelecionar = { display: "none" };

  if (selecionar) {
    paddingValorTotal = { paddingRight: '1vh' };
    displayDoSelecionar = { display: "" };
  }
  if (!espacamento) {
    paddingValorTotal = { paddingRight: '1vh' };
  }

  const incrementarQuantidade = () => {
    if (isChecked && quantidade < item.quantidade) {
      const novaQuantidade = quantidade + 1;
      setQuantidade(novaQuantidade);
      setAlterado(true);
      onUpdateQuantidadeSelecionada(item.codigoDaPeca, novaQuantidade);
    }
  };


  const decrementarQuantidade = () => {
    if (isChecked && quantidade > 1) {
      const novaQuantidade = quantidade - 1;
      setQuantidade(novaQuantidade);
      setAlterado(true);
      onUpdateQuantidadeSelecionada(item.codigoDaPeca, novaQuantidade);
    }
  };

  const handleClickCheckbox = () => {
    setChecked(!isChecked);
    onToggleItemSelecionado(item);
    if (alterado) {
      setQuantidade(item.quantidade)
      onUpdateQuantidadeSelecionada(item.codigoDaPeca, item.quantidade);
    }
  };
  const valorSubTribFormatado = formatarValor(item.valorSubTrib);
  const ipiFormatado = formatarValor(item.ipi);
  const baseIcmsFormatado = formatarValor(item.baseIcms);
  const icmsFormatado = formatarValor(item.icms);
  const fcpStFormatado = formatarValor(item.fcpSt);
  const valorTotalFormatado = formatarValor(item.valorTotal);
  const valorUnidadeFormatado = formatarValor(item.valorUnidade);

  return (
    <tr style={{ color: "#041E42" }} className={isChecked ? 'itemSelecionadoNoFluxo' : ''}>
      <td style={{ ...displayDoSelecionar }}>
        <label className='chk'>
          <input
            type='checkbox'
            style={{ display: 'none' }}
            defaultChecked={false}
            checked={isChecked}
            onChange={handleClickCheckbox}
          />
          {isChecked ? <span>✔</span> : <span></span>}
        </label>
      </td>
      <td style={{ ...displayDoSelecionar }}>
        <div className="quantidadeSeletor">
          <input style={{ border: 'none' }} type="number" value={quantidade} />
          <div>
            <div>
              <button onClick={incrementarQuantidade} style={{ background: "none", border: "none", cursor: 'pointer' }}>
                <img src={setaParaCima} style={{ width: '12px', height: '8px' }} alt=''></img>
              </button>
            </div>
            <div>
              <button onClick={decrementarQuantidade} style={{ background: "none", border: "none", cursor: 'pointer' }}>
                <img src={setaParaBaixo} style={{ width: '12px', height: '8px' }} alt=''></img>
              </button>
            </div>
          </div>
        </div>
      </td>
      <td>{item.codigoDaPeca}</td>
      <td>{quantidadeSelecionada != null ? quantidadeSelecionada : item.quantidade}</td>
      <td>{item.cst !== undefined ? item.cst : 'N/A'}</td>
      <td>{item.cfop}</td>
      <td>{item.valorSubTrib !== undefined ? valorSubTribFormatado : 'N/A'}</td>
      <td>{ipiFormatado}</td>
      <td>{item.aliqIpi}</td>
      <td>{baseIcmsFormatado}</td>
      <td>{icmsFormatado}</td>
      <td>{item.aliqIcms}</td>
      <td>{fcpStFormatado}</td>
      <td>{valorUnidadeFormatado}</td>
      <td className='tirarPaddingRightParaMobile' style={{ ...paddingValorTotal }}>{valorTotalFormatado}</td>
    </tr>
  );
};

export default ItensNotaFiscal;
