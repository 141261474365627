import React from 'react';
import vejaComoChegarIcon from './iconVejaComoChegar.svg';
import { Link } from 'react-router-dom';

const UnidadeDistribuidora = ({ titulo, local, endereco, descricao1, descricao2, imgSrc, mobile, reverse, ultima, vejaComoChegarUrl }) => {
  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingLeft: '5%',
          paddingRight: '5%',
          flexDirection: mobile ? 'column-reverse' : reverse ? 'row-reverse' : 'row',
        }}
      >
        <div className='divParaMobileDosTextosDistribuidoras' style={{ width: !mobile ? '47.5%' : '100%' }}>
          <div>
            <h2 className='subTituloUnidades'>{titulo} <p style={{margin: '0'}} className='subTituloUnidadesLocal'> {local}</p></h2>
          </div>
          <label className='endereçoUnidades'>{endereco}</label>
          <p style={{ marginTop: '3em'}} className='textoUnidades'>
            {descricao1}
          </p>
          {descricao2 && (
            <p className='textoUnidades'>
              {descricao2}
            </p>
          )}
          <Link target="_blank" to={vejaComoChegarUrl} ><label className='vejaComoChegarLabel'>
            <img src={vejaComoChegarIcon} alt='' />Veja como chegar
          </label>
          </Link>
        </div>
        <img className='imagemUnidadeDistribuidora' style={{ width: !mobile ? '47.5%' : '100%' }} src={imgSrc} alt='' />
      </div>
      {!ultima ? <hr className='linhaSeparatoriaDasUnidades'></hr> : null}
    </>
  );
}
export default UnidadeDistribuidora;