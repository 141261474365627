import React, { useEffect, useState } from 'react';
import Protocolo from '../../componentes/Protocolo';
import ProtocoloMobile from '../../componentes/ProtocoloMobile';
import { Link } from 'react-router-dom';
import "./ListaDeProtocolos.css";
import Header from '../../componentes/Header';
import BarraDePesquisa from '../../componentes/BarraDePesquisa';
import Cookies from 'js-cookie';
import iconMessage1 from '../Historico/imgs/icon1Mensagem.svg';
import iconMessage2 from '../Historico/imgs/icon2Mensagem.svg';
import ListagemDeChats from '../../componentes/ListagemDeChats';

const ListaDeProtocolos = () => {
  const [mobile, setMobile] = useState(window.innerWidth <= 850);

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [listaDeProtocolos, setListaDeProtocolos] = useState([]);
  const [filtroPesquisa, setFiltroPesquisa] = useState('');
  const [chatVisible, setChatVisible] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = new Headers({
            'Authorization': `Bearer ${token}`,
          });

          const response = await fetch(`${process.env.REACT_APP_API_URL}/protocolo/listarProtocolos`, { headers });

          if (response.ok) {
            const data = await response.json();
            setListaDeProtocolos(data);

          } else {
            console.error('Erro na requisição:', response.statusText);
          }
        } else {
          console.error('Token não encontrado no localStorage.');
        }
      } catch (error) {
        console.error('Erro ao obter notas fiscais:', error);
      }
    };

    fetchData();
  }, []);
  const AbrirChat = () => {
    setChatVisible(true);
    document.body.classList.add('no-overflow');
  };

  const FecharChat = () => {
    setChatVisible(false);
    document.body.classList.remove('no-overflow');
  };
  return (
    <div>
      <Header logado={true} />
      <div style={{ display: chatVisible ? 'flex' : 'none' }}>
        <div style={{ position: 'absolute', height: '100%', width: '100%', top: '120px', left: '0', backgroundColor: 'rgba(128, 128, 128, 0.363)' }} onClick={FecharChat}></div>
        <ListagemDeChats onClose={FecharChat} />
      </div>
      <div className='conteudoDaPagina'>
        <Link to='/historico'>
          <h1 className='botaoDeVoltar'> ❮  Voltar</h1>
        </Link>
        <div className='classeParaFlexDirectionColumnMobile' style={{ display: "flex", marginBottom: "1lh", justifyContent: "space-between" }}>
          <h1 className='tituloDaListaDeProtocolos'>Meus Protocolos</h1>
          <div className='headerDoListarProtocolosMobile' style={{ display: 'flex', alignItems: 'center', width: '70%', justifyContent: 'space-between' }}>
            <BarraDePesquisa text="Pesquisar" onPesquisaChange={setFiltroPesquisa} />
            {mobile ? (
              <div className='div1DoHeaderListarProtocolosMobile'>
                <Link to={"/abrirNovoProtocolo"} style={{ display: 'contents' }}>
                  <button className='botaoAbrirNovoProtocoloListaProtocolos'>Abrir novo Protocolo</button>
                </Link>
                <div onClick={AbrirChat} className="divDoBotaoChat botaoChatDoListarProtocolos">
                  <img src={iconMessage1} alt=''></img>
                  <h2>Abrir mensagens</h2>
                  <img src={iconMessage2} alt=''></img>
                </div>
              </div>
            ) : <>
              <Link to={"/abrirNovoProtocolo"} style={{ display: 'contents' }}>
                <button className='botaoAbrirNovoProtocoloListaProtocolos'>Abrir novo Protocolo</button>
              </Link>
              <div onClick={AbrirChat} className="divDoBotaoChat botaoChatDoListarProtocolos">
                <img src={iconMessage1} alt=''></img>
                <h2>Abrir mensagens</h2>
                <img src={iconMessage2} alt=''></img>
              </div>
            </>}

          </div>
        </div>
        {!mobile ? (
          <div>
            <table className='tabelaListaDeProtocolos'>
              <thead>
                <tr>
                  <th style={{ paddingLeft: '1em' }} >Nº do protocolo</th>
                  <th>Tipo</th>
                  <th>Status</th>
                  <th colSpan="2">Data da criação</th>
                </tr>
              </thead>
              <tbody>
                {listaDeProtocolos
                  .filter(protocolo => String(protocolo.id).toLowerCase().includes(filtroPesquisa.toLowerCase()))
                  .map((protocolo) => (
                    <Protocolo key={protocolo.id} protocolo={protocolo} selecionar={false} />
                  ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '1em' }}>
            {listaDeProtocolos
              .filter(protocolo => String(protocolo.id).toLowerCase().includes(filtroPesquisa.toLowerCase()))
              .map((protocolo) => (
                <ProtocoloMobile key={protocolo.id} protocolo={protocolo} selecionar={false} />
              ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ListaDeProtocolos;
