import React from "react";
import './InfoBox.css';
import fileExample from './imgs/fileExample.png'
import iconNumber1 from './imgs/iconNumber1.png'
import iconNumber2 from './imgs/iconNumber2.png'
import iconNumber3 from './imgs/iconNumber3.png'
import iconNumber4 from './imgs/iconNumber4.png'
import iconDownload from './imgs/iconDownload.png'
import iconX from './imgs/iconX.png'

const InfoBox = ({ toggleInfoBox, garantia }) =>{
    const downloadFile = () => {
        const link = document.createElement('a');
        link.href = fileExample; // Use o arquivo importado
        link.download = 'modelo-orçamento.png'; // Nome do arquivo que será baixado
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

return(<div className='infoBox'>
<span onClick={toggleInfoBox}>
    <img className='closeButton' alt=""  src={iconX}/>
</span>
<div className='infoDiv'>
<div className='infoDivLeft'>
    {garantia ? (
        <h1 className='infoDivH1'>Como preencher e enviar os comprovantes
        ou orçamentos realizados para Garantia</h1>
    ) : (
        <h1 className='infoDivH1'>Como preencher e enviar os comprovantes
        ou orçamentos realizados para Devolucao</h1>
    )}
    <div className='infoDivPhrases'><img className='infoDivLeftImg' alt="" src={iconNumber1}/>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
    <div className='infoDivPhrases'><img className='infoDivLeftImg' alt=""  src={iconNumber2}/>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
    <div className='infoDivPhrases'><img className='infoDivLeftImg' alt=""  src={iconNumber3}/>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
    <div className='infoDivPhrases'><img className='infoDivLeftImg' alt=""  src={iconNumber4}/>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</div>
</div>
<div className='infoDivRight'>
    <img className='infoDivImg' alt="" src={fileExample}/>
    <div className='infoDivRightButton' onClick={downloadFile}>
        <img className='infoDivRightImg' src={iconDownload}  alt="" />
        <h1 className='infoDivRightH1'>Baixar modelo de orçamento</h1>
    </div>
</div>
</div>
</div>
)};

export default InfoBox;