
import Header from '../../componentes/Header';
import SelecionarNotaOuCupom from '../../componentes/SelecionarNotaOuCupom';
import "./SelecionarNotaOuCupomPage.css"
function SelecionarNotaOuCupomPage() {
  return (
    <div className="SelecionarNotaOuCupomP">
      <div className="content" style={{height: '100lh'}}>
        <Header logado={true}/>
        <SelecionarNotaOuCupom />
      </div>
    </div>
  );
}
export default SelecionarNotaOuCupomPage;