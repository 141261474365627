import Header from '../../componentes/Header';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CampoTexto from '../../componentes/CampoTexto';
import './Cadastro.css';
import CheckedImg from './imgs/checked.png';
import UncheckedImg from './imgs/unchecked.png';
import { Link } from 'react-router-dom';

function Cadastro() {
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const [form, setForm] = useState({
    nome: '',
    login: '',
    telefone: '',
    notificacoes: false,
    email: '',
    senha: '',
  });
  const [textoSenha, setTextoSenha] = useState('');

  const handleAcceptWpChange = () => {
    setForm({ ...form, acceptWp: !form.acceptWp });
  };

  const handleChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });
    if (name === 'senha') {
      const isPasswordValid = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(value);
      if (!isPasswordValid) {
        setIsButtonDisabled(true)
        setTextoSenha('❌ Mínimo 8 caracteres com letras e números.')
      } else {
        setIsButtonDisabled(false)
        setTextoSenha('✅ Mínimo 8 caracteres com letras e números.')
      }
    }
    if(name === 'login'){
      setErroRegistroCpf(null);
    }
  };
  const [erroRegistroCpf, setErroRegistroCpf] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/registro`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });

      if (response.ok) {
        const data = await response.json();
        if (data.statusCode === "CONFLICT") {
          //console.log("Usuário já cadastrado com este CPF / CNPJ!");
          setErroRegistroCpf("Usuário já cadastrado com este CPF / CNPJ!");
        } else if (data.statusCode === "BAD_REQUEST") {
          setErroRegistroCpf("O usuário não pode ser nulo!");
        } else if (data.statusCode === "CREATED") {
          navigate("/login");
          setErroRegistroCpf(null);
        }
      }
    } catch (error) {
      console.log('Erro ao cadastrar usuário.' + error);
    }
  };
  return (
    <div className="Login">
      <Header />
      <div className="contentRegistro">
        <div className="pageContainer">
          <div className="containerFormularioCadastro">
            <Link to={"/login"}>
              <h1 className={"botaoDeVoltar"}>❮  Voltar</h1>
            </Link>
            <div className='divCadastro'>
              <h1 className='cadastroConta'>Cadastro de conta</h1>
              <form onSubmit={handleSubmit} className="card-grid">
                <div style={{ 'gridRow': '1', 'gridColumn': '1' }}>
                  <CampoTexto
                    label="Nome ou Razão Social"
                    type="text"
                    name="nome"
                    onChange={handleChange}
                  /><br />
                </div>
                <div style={{ 'gridRow': '2', 'gridColumn': '1' }}>
                {erroRegistroCpf ? <div className='erroRegistroCpf'>{erroRegistroCpf}</div> : null}
                  <CampoTexto
                    label="CPF ou CNPJ"
                    type="text"
                    name="login"
                    onChange={handleChange}
                    borderColor={erroRegistroCpf}
                  /><br />
                </div>
                <div style={{ 'gridRow': '3', 'gridColumn': '1' }}>
                  <CampoTexto
                    label="Telefone"
                    type="text"
                    name="telefone"
                    onChange={handleChange}
                  /><br />
                </div>
                <div style={{ 'gridRow': '4', 'gridColumn': '1' }} className="accept-wp">
                  {form.acceptWp ? (
                    <img className='checkedImgCadastro' src={CheckedImg} alt="Correct" onClick={handleAcceptWpChange} style={{ cursor: 'pointer' }} />
                  ) : (
                    <img className='checkedImgCadastro' src={UncheckedImg} alt="Incorrect" onClick={handleAcceptWpChange} style={{ cursor: 'pointer' }} />
                  )}
                  <span>Aceito receber notificações por WhatsApp</span>
                </div>
                <div style={{ 'gridRow': '1', 'gridColumn': '2' }}>
                  <CampoTexto
                    label="Email"
                    type="email"
                    name="email"
                    onChange={handleChange}
                  /><br />
                </div>
                <div style={{ 'gridRow': '2', 'gridColumn': '2' }}>
                  <CampoTexto
                    label="Criar Senha"
                    type="password"
                    name="senha"
                    onChange={handleChange}
                  /><br />
                </div>
                <div className='textoAdvertenciaSenha' name="textoSenha" style={{ 'gridRow': '3', 'gridColumn': '2' }}>{textoSenha}</div>
                <div style={{ 'gridRow': '3', 'gridColumn': '2' }}><button className='buttonCriarConta' type="submit" disabled={isButtonDisabled} style={{ opacity: isButtonDisabled ? 0.5 : 1, cursor: isButtonDisabled ? 'not-allowed' : 'pointer' }}>Criar conta</button></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cadastro;