import Header from '../../componentes/Header';
import './CriarNovaSenha.css';
import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import iconPositivo from './img/iconPositivo.svg';
import iconNegativo from './img/iconNegativo.svg';
import { Link } from 'react-router-dom';
function CriarNovaSenha() {
  const location = useLocation();
  const emailEscrito = location && location.state ? location.state.emailEscrito : [];
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const [finalizou, setFinalizou] = useState(false);
  const [textoSenha, setTextoSenha] = useState('');
  const [textoSenha2, setTextoSenha2] = useState('');
  const [senha, setSenha] = useState("");
  const alterarSenha = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/alterarSenha`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailEscrito, novaSenha: senha }),
      });
      if (response.ok) {
        const confirmacao = await response.json();
        if (confirmacao === true) {
          setFinalizou(true);
        } else {
          setTextoSenha2(<label><img src={iconNegativo} alt=''></img>A senha digitada é igual a anterior!</label>);
        }
      } else {
        console.error('Erro');
      }
    } catch (error) {
      console.error('Erro: ', error);
    }
  };
  const handleNovaSenha = (event) => {
    const isPasswordValid = /^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(event.target.value);
    if (!isPasswordValid) {
      setIsButtonDisabled(true)
      setTextoSenha(<label><img src={iconNegativo} alt=''></img>Mínimo 8 caracteres com letras e números.</label>);
    } else {
      setIsButtonDisabled(false)
      setTextoSenha(<label><img src={iconPositivo} alt=''></img>Mínimo 8 caracteres com letras e números.</label>);
    }
    setTextoSenha2("");
    setSenha(event.target.value);
  }
  return (
    <div className="Login">
      <Header />
      <div className="contentLogin">
        <div className="pageContainer">
        <div style={{ display: !finalizou ? 'block' : 'none' }} className="divCriarNovaSenhaDeAcesso">
            <h3 className='criarNovaSenhaDeAcessoTitulo'>Código validado</h3>
            <div className='divDoInputDeCodigoEnviadoPorEmail'>
              <label>Escreva sua nova senha</label>
              <input type="password" onChange={handleNovaSenha}></input>
              <div style={{height: '6em'}}>
                <div className='textoAvisoSenha' name="textoSenha">{textoSenha}</div>
                <div className='textoAvisoSenha' name="textoSenha2">{textoSenha2}</div>
              </div>
            </div>
            <div onClick={alterarSenha} disabled={isButtonDisabled} style={{ opacity: isButtonDisabled ? 0.5 : 1, cursor: isButtonDisabled ? 'not-allowed' : 'pointer' }} className='validarCodigoButton'>Criar nova senha</div>
          </div>
          <div style={{ display: finalizou ? 'block' : 'none' }} className="divCriarNovaSenhaDeAcesso">
            <h3 className='criarNovaSenhaDeAcessoTitulo'>Senha criada com sucesso!</h3>
            <Link to={"/login"}><div className='validarCodigoButton'>Fazer login</div></Link>
          </div>
        </div >
      </div>
    </div>
  );
}

export default CriarNovaSenha;