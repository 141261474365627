import Header from '../../../componentes/Header';
import React, { useState, useEffect } from 'react';
import './ListagemUsuariosAdmin.css';
import Cookies from 'js-cookie';
import { useNavigate } from 'react-router-dom';
import BotaoDeVoltarAdmin from '../../../componentes/BotaoDeVoltarAdmin';
import iconEditar from './imgs/iconEditarAdmin.svg';
import iconFecharPopup from './imgs/iconFecharPopupAdmin.svg';
import tornarAdminIcon from './imgs/superAdminIconBlack.svg';
import removerAdminIcon from './imgs/superAdminIconRed.svg';
import acessarPerfilIcon from './imgs/acessarPerfilIcon.svg';
import Checkbox from '../../../componentes/Checkbox';
import pesquisarUsuarioIcon from './imgs/pesquisarUsuarioIcon.svg';
function ListagemUsuariosAdmin() {
  const [idUsuarioEditar, setIdUsuarioEditar] = useState(null);
  const [filtroPesquisaUsuarios, setFiltroPesquisaUsuarios] = useState('');

  const [mostrarPopupAcessarPerfil, setMostrarPopupAcessarPerfil] = useState(false);
  const [idPerfilAcessar, setIdPerfilAcessar] = useState(null);

  const [usuarioSelecionadoParaEditar, setUsuarioSelecionadoParaEditar] = useState({
    nome: '',
    nomeFantasia: '',
    cpf: '',
    telefone: '',
    email: '',
    inscricaoEstadual: '',
    cep: '',
    rua: '',
    cidade: '',
    unidadeFederativa: '',
    numero: '',
    notificacoes: false,
  });
  function limparUsuarioSelecionado() {
    setIdUsuarioEditar(null);
    setUsuarioSelecionadoParaEditar({
      nome: '',
      nomeFantasia: '',
      cpf: '',
      telefone: '',
      email: '',
      inscricaoEstadual: '',
      cep: '',
      rua: '',
      cidade: '',
      unidadeFederativa: '',
      numero: '',
      notificacoes: false,
    });
  }
  const [usuarios, setUsuarios] = useState([]);
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });

  const [mostrarPopupEditar, setMostrarPopupEditar] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });

          if (!data) navigate('/login');
        } catch {
          setStatusUsuario({ logado: false, admin: false });
          navigate('/login');
        }
      };
      fetchData();
    } else {
      navigate('/login');
    }
  }, [navigate]);

  useEffect(() => {
    window.scrollTo(0, 0);
    listarUsuarios();
  }, []);

  // Listar usuários
  async function listarUsuarios() {
    try {
      const token = Cookies.get('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/listarTodosUsuarios`, {
        method: 'GET',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setUsuarios(data);
    } catch (error) {
      console.log(error);
    }
  }

  // Função para salvar alterações no produto
  async function salvarAlteracoesUsuario() {
    try {
      const token = Cookies.get('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/salvarAlteracoesUsuario/${idUsuarioEditar}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(usuarioSelecionadoParaEditar),
      });

      if (response.ok) {
        setMostrarPopupEditar(false);
        listarUsuarios();
        console.log('Alterações salvas com sucesso!');
      } else {
        const errorText = await response.text();
        console.log(`Erro ao atualizar o produto: ${errorText}`);
      }
    } catch (error) {
      console.error('Erro ao se comunicar com o servidor:', error);
    }
  }

  async function removerAdminUsuario(id) {
    try {
      const token = Cookies.get('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/removerAdminDeUsuario/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        listarUsuarios();
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function concederAdminUsuario(id) {
    try {
      const token = Cookies.get('token');
      const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/tornarUsuarioAdmin/${id}`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        listarUsuarios();
      }
    } catch (error) {
      console.log(error);
    }
  }
  async function detalharUsuarioParaEditar(id) {
    if (id !== null) {
      try {
        const token = Cookies.get('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/detalharUsuario/${id}`, {
          method: 'GET',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        const data = await response.json();
        setUsuarioSelecionadoParaEditar(data);
      } catch (error) {
        console.log(error);
      }
    }
  }
  async function acessarPerfilDeUsuario() {
    if (idPerfilAcessar !== null) {
      try {
        const token = Cookies.get('token');
        const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/logarEmUsuario/${idPerfilAcessar}`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        if (!response.ok) {
          throw new Error(`Erro ao logar: ${response.status} - ${response.statusText}`);
        }
  
        const data = await response.json();
  
        if (data.token) {
          Cookies.set('token', data.token);
          window.location.href = '/historico';
        } else {
          throw new Error('Token JWT não encontrado na resposta.');
        }
      } catch (error) {
        console.log(`Erro ao acessar perfil: ${error.message}`);
      }
    }
  }
  const [nomeUsuario, setNomeUsuario] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = new Headers({
            'Authorization': `Bearer ${token}`,
          });

          const response = await fetch(`${process.env.REACT_APP_API_URL}/protocolo/resgatarNomeUsuario`, { headers });
          const data = await response.json();
          setNomeUsuario(data.nome);
        }
      } catch (error) {
        console.error('Erro ao obter nome de usuário:', error);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="homeAdmin-wrapper">
      <Header logado={statusUsuario.logado} admin={statusUsuario.admin} />

      {mostrarPopupAcessarPerfil && (
        <div className='marcasAdmin-popupCadastrarNovaMarca' style={{ maxWidth: '28em' }}>
          <h2>Acessar Perfil</h2>
          <p style={{ marginBottom: '4em' }}>Você tem certeza de que deseja acessar o perfil deste usuário? Isso irá desconectar você da sua conta.</p>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <button style={{ padding: '1em 2em' }} className='admin-botaoAzulComHover' onClick={() => { setMostrarPopupAcessarPerfil(false); setIdPerfilAcessar(null); }}>Não, voltar</button>
            <button style={{ padding: '1em 2em' }} className='admin-botaoBrancoComHover' onClick={acessarPerfilDeUsuario}>Sim, acessar</button>
          </div>
        </div>
      )}

      {mostrarPopupEditar && (
        <div className="marcasAdmin-popupCadastrarNovaMarca" style={{ minWidth: '30vw', maxHeight: '75vh', overflowX: 'scroll', top: '55%' }}>
          <h2>Editar usuario <img onClick={() => { setMostrarPopupEditar(false); limparUsuarioSelecionado() }} alt='Fechar popup' src={iconFecharPopup}></img></h2>
          <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'column', gap: '2em' }}>
            <div className='produtosAdmin-divMaiorInputs'>
              <div className='produtosAdmin-divDosInputsDeTexto'>
                <div className='divMaiorDadosCadastrais'>
                  <div className='divGeralDoDadosCadastrais' style={{ width: 'auto', paddingLeft: '1em', paddingRight: '1em', paddingTop: '0', paddingBottom: '0', boxShadow: 'none' }}>
                    <div className={'divDoInputDeDadosCadastrais'} >
                      <label>Nome ou Razão Social</label>
                      <input type="text" value={usuarioSelecionadoParaEditar.nome} onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, nome: e.target.value })}></input>
                    </div>
                    <div className={'divDoInputDeDadosCadastrais'} >
                      <label>Nome Fantasia</label>
                      <input type="text" value={usuarioSelecionadoParaEditar.nomeFantasia} onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, nomeFantasia: e.target.value })}></input>
                    </div>
                    <div className={'divDoInputDeDadosCadastrais'} >
                      <label>CPF ou CNPJ</label>
                      <input type="number" value={usuarioSelecionadoParaEditar.cpf} onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, cpf: e.target.value })}></input>
                    </div>
                    <div className={'divDoInputDeDadosCadastrais'} >
                      <label>Inscrição Estadual</label>
                      <input type="number" value={usuarioSelecionadoParaEditar.inscricaoEstadual} placeholder='Isento' onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, inscricaoEstadual: e.target.value })}></input>
                    </div>
                    <div className={'divDoInputDeDadosCadastrais'} >
                      <label>E-mail</label>
                      <input type="text" value={usuarioSelecionadoParaEditar.email} onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, email: e.target.value })}></input>
                    </div>
                    <div className={'divDoInputDeDadosCadastrais'} >
                      <label>Telefone</label>
                      <input type="number" value={usuarioSelecionadoParaEditar.telefone} onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, telefone: e.target.value })}></input>
                    </div>
                    <div className={'divDoCheckBoxNotificacoes'} >
                      <Checkbox isChecked={usuarioSelecionadoParaEditar.notificacoes} onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, notificacoes: !usuarioSelecionadoParaEditar.notificacoes })}></Checkbox>
                      <label>Aceito receber notificações por WhatsApp.</label>
                    </div>
                    <div className={'divDoInputDeDadosCadastrais'} >
                      <label>CEP</label>
                      <input type="number" value={usuarioSelecionadoParaEditar.cep} onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, cep: e.target.value })}></input>
                    </div>
                    <div className={'divDoInputDeDadosCadastrais'} >
                      <label>Rua</label>
                      <input type="text" value={usuarioSelecionadoParaEditar.rua} onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, rua: e.target.value })}></input>
                    </div>
                    <div className={'divDoInputDeDadosCadastrais'} >
                      <label>Cidade</label>
                      <input type="text" value={usuarioSelecionadoParaEditar.cidade} onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, cidade: e.target.value })}></input>
                    </div>
                    <div className='divUnidFedNumero'>
                      <div className={'divDoInputDeDadosCadastrais'} >
                        <label>UF</label>
                        <input type="text" value={usuarioSelecionadoParaEditar.unidadeFederativa} onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, unidadeFederativa: e.target.value })}></input>
                      </div>
                      <div className={'divDoInputDeDadosCadastrais'} >
                        <label>Número</label>
                        <input type="number" value={usuarioSelecionadoParaEditar.numero} onChange={(e) => setUsuarioSelecionadoParaEditar({ ...usuarioSelecionadoParaEditar, numero: e.target.value })}></input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <button className='admin-botaoAzulComHover' style={{ padding: '1em 8em' }} onClick={salvarAlteracoesUsuario}>Salvar</button>
          </div>
        </div>
      )}


      <div className="homeAdmin-content">
        <BotaoDeVoltarAdmin />
        <div className="marcasAdmin-titleDiv">
          <h1 className="homeAdmin-title">Usuários</h1>
          <div className='divPesquisarUsuarioListaAdmin' onClick={() => document.getElementById('inputBuscar').focus()}>
            <img src={pesquisarUsuarioIcon} alt='' />
            <input id='inputBuscar' type='text' placeholder='Buscar' value={filtroPesquisaUsuarios} onChange={(e) => setFiltroPesquisaUsuarios(e.target.value)} />
          </div>
        </div>
        <div className="marcasAdmin-grid">
          <div className="marcasAdmin-grid-header" style={{ gridTemplateColumns: '8fr' }}>
            <div className="header-item headerColunaDaImagemGrid">Nome</div>
          </div>
          {usuarios.filter(usuario => usuario.nomeUsuario && usuario.nomeUsuario.toLowerCase().startsWith(filtroPesquisaUsuarios.toLowerCase())).map((usuario) => (
            <div className="marcasAdmin-grid-row" key={usuario.id} style={{ gridTemplateColumns: usuario.isAdmin != null ? '5fr 1fr 1fr 0.5fr' : '5fr 1fr 0.5fr' }}>
              <div className="grid-item colunaDaImagemGrid">{usuario.nomeUsuario}</div>
              <div className="grid-item" style={{ textAlign: 'right', justifyContent: 'flex-end' }}><button onClick={() => {setIdPerfilAcessar(usuario.id); setMostrarPopupAcessarPerfil(true)}} className='acessarPerfilText'><img src={acessarPerfilIcon} alt=''></img>Acessar Perfil</button></div>
              {usuario.isAdmin != null && (
              usuario.nomeUsuario !== nomeUsuario ? (
                <div className="grid-item">
                  <button
                    onClick={usuario.isAdmin === true ? (() => removerAdminUsuario(usuario.id)) : (() => concederAdminUsuario(usuario.id))}
                    className={usuario.isAdmin === true ? 'removerAdminText' : 'tornarAdminText'}
                  >
                    {usuario.isAdmin === true ? (
                      <><img alt='' src={removerAdminIcon}></img>Remover Admin</>) : (<><img alt='' src={tornarAdminIcon}></img>Tornar Admin</>
                    )}
                  </button>
                </div>
              ):(
                <div className="grid-item" style={{color: '#6B6B6B', fontSize: '0.9em', paddingLeft: '1.8em'}}>SuperAdmin</div>
              ))}
              <div className="grid-item" style={{ textAlign: 'right', justifyContent: 'flex-end' }}>
                <div className='divBotoesGridItem'>
                  <button
                    onClick={() => {
                      setIdUsuarioEditar(usuario.id);
                      detalharUsuarioParaEditar(usuario.id);
                      setMostrarPopupEditar(true);
                    }}
                  >
                    <img src={iconEditar} alt="" />Editar
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ListagemUsuariosAdmin;