import React from 'react';
import { Link } from 'react-router-dom';
const BotaoDeVoltar = (props) => {
    const { link, text, className} = props;

    return (
        <Link to={link}>
            <h1 className={className}>{text}</h1>
        </Link>
    );
}

export default BotaoDeVoltar;