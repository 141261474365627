import Header from '../../../componentes/Header';
import React, { useState } from 'react';
import './Institucional.css';
import { useEffect } from 'react';
import BottomSitePublico from '../../../componentes/BottomSitePublico';
import Cookies from 'js-cookie';
import imagemHeaderInstitucional from './imgs/imagemHeaderInstitucional.png';

import iconCardMissao from './imgs/iconCardMissao.svg';
import iconCardObjetivo from './imgs/iconCardObjetivo.svg';
import iconCardValores from './imgs/iconCardValores.svg';
import iconCardVisao from './imgs/iconCardVisao.svg';

import fotoFachada1991 from './imgs/fotoFachada1991.png';
import fotoFachada1992 from './imgs/fotoFachada1992.png';
import fotoFachada2001 from './imgs/fotoFachada2001.png';
import fotoFachada2015 from './imgs/fotoFachada2015.png';
import fotoFachada2018 from './imgs/fotoFachada2018.png';

import banner3Institucional from './imgs/banner3Institucional.jpg';

import imagemResponsabilidadeAmbiental from './imgs/imagemResponsabilidadeAmbiental.svg';

import imagemGirosFreteNeutro from './imgs/imagemGirosFreteNeutro.jpg';

import ItemLinhaDoTempoInstitucional from '../../../componentes/ItemLinhaDoTempoInstitucional';

import imagem33anos from './imgs/imagemResultadoEmNumeros.jpg';

import iconItensCadastrados from './imgs/iconItensCadastrados.svg';

import iconItensEmEstoque from './imgs/iconPecasEmEstoque.svg';

function Institucional() {
  const [mobile, setMobile] = useState(window.innerWidth <= 850);

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setStatusUsuario({ logado: true, admin: false });
      const fetchData = async () => {
        try {
          if (token) {
            const headers = new Headers({
              'Authorization': `Bearer ${token}`,
            });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, { headers });
            const data = await response.json();
            setStatusUsuario({ logado: true, admin: data });
          }
        } catch (error) {
          setStatusUsuario({ logado: false, admin: false });
        }
      };

      fetchData();
    } else {
      setStatusUsuario({ logado: false, admin: false });
    }
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="sitePublicoDivMaior">
      <Header logado={statusUsuario.logado ? true : false} admin={statusUsuario.admin ? true : false} />
      <div className="divMaiorInstitucional">
        <img style={{ width: '100%' }} src={imagemHeaderInstitucional} alt='Imagem da fachada da distribuidora/Loja de Porto Alegre escrito Giros Peças'></img>
        <div style={{ backgroundColor: '#FFFFFF', paddingTop: !mobile ? '4em' : '1em', marginTop: '-0.5em' }}>
          <h1 className='tituloSobreAGiros'>Sobre a Giros</h1>
          <div className='divMaiorSobreEHistoriaGiros'>
            <div className="sobreAGiros">
              <p>Na Giros, valorizamos a confiança dos nossos clientes e o bem-estar dos nossos colaboradores.</p>
              <p>Nossa dedicação ao longo dos anos reflete nosso compromisso com a excelência e a responsabilidade social.</p>
            </div>
            {mobile ? (<h1 className='tituloSobreAGiros2'>Na Matriz, contamos com uma estrutura com mais de 50 mil itens ativos em um espaço de 1.400 m²</h1>) : null}
            <div className="divHistoriaGiros">
              <h2>História de Sucesso</h2>
              <p>Começamos com peças como virabrequim e cabeçote. Hoje, somos um dos maiores distribuidores de autopeças do estado.</p>
              <h2>Expansão e Parcerias</h2>
              <p>De consumidores finais a retificadoras e autopeças, nosso portfólio cresceu para atender a uma ampla gama de clientes.</p>
            </div>
          </div>
          {!mobile ? (<h1 className='tituloSobreAGiros2'>Na Matriz, contamos com uma estrutura com mais de 50 mil itens ativos em um espaço de 1.400 m²</h1>) : null}
        </div>
        <div className="divMaiorDosCards">
          <div className="card card1">
            <h3><img src={iconCardMissao} alt='icone missão'></img>MISSÃO</h3>
            <p>Proporcionar qualidade e excelência, comercializar produtos automotivos, oferecendo inovação nos produtos e atendimentos, preservando a ética e a transparência nas negociações.</p>
          </div>
          <div className="card card2">
            <h3><img src={iconCardVisao} alt='icone visão'></img>VISÃO</h3>
            <p>Conquistar e manter a liderança no mercado do comércio de autopeças, de forma a preservar uma política de trabalho séria e honesta, sendo referencial de excelência, valorizados pelo entusiasmo e comprometimento de nossa equipe de colaboradores.</p>
          </div>
          <div className={!mobile ? "card card2" : "card card1"}>
            <h3><img src={iconCardValores} alt='icone valores'></img>VALORES</h3>
            <p>Trabalho em equipe, integridade absoluta, relacionamento interpessoal, valorização do ser humano, inovação, flexibilidade e resultado, gerar empregos, conquistar e manter clientes de um princípio ético e garantir a satisfação crescente dos colaboradores.</p>
          </div>
          <div className={!mobile ? "card card1" : "card card2"}>
            <h3><img src={iconCardObjetivo} alt='icone objetivo'></img>OBJETIVO</h3>
            <p>A Giros Peças quer estar entre as melhores empresas do mercado sendo um referencial em vendas de peças e acessórios para veículos automotivos, garantindo aos clientes confiabilidade dos serviços e produtos.</p>
          </div>
        </div>
        <div className='divLinhaDoTempo'>
          <h1 className='tituloLinhaDoTempo'>NOSSA HISTÓRIA</h1>
          <ItemLinhaDoTempoInstitucional mobile={mobile} ano="1991" imagem={fotoFachada1991} descricao="Em março desse ano, demos início às atividades. Nosso primeiro endereço foi na Av. do Forte, 362. Nesse mesmo local, uma loja de venda de carros usados, chamada Giros Car, inspirou a criação do nome Giros Peças."></ItemLinhaDoTempoInstitucional>
          <ItemLinhaDoTempoInstitucional mobile={mobile} ano="1992" imagem={fotoFachada1992} descricao="Em janeiro desse ano, nos mudamos para Av. Cairu, 1247. A loja contava com infraestrutura de 100m² e estacionamento."></ItemLinhaDoTempoInstitucional>
          <ItemLinhaDoTempoInstitucional mobile={mobile} ano="2001" imagem={fotoFachada2001} descricao="Em abril, adquirimos um terreno na Av. Cairu, número 1045, nossa sede própria na qual nos localizamos até hoje. Temos uma infraestrutura com 2.500 m² e disponibilidade de estacionamento."></ItemLinhaDoTempoInstitucional>
          <ItemLinhaDoTempoInstitucional mobile={mobile} ano="2006" descricao="Começamos uma pequena mudança no nosso público e passamos a distribuir peças de grandes fábricas brasileiras, fábricas que tem seus produtos em montadoras e fábricas com renome mundial no segmento de autopeças."></ItemLinhaDoTempoInstitucional>
          <ItemLinhaDoTempoInstitucional mobile={mobile} ano="2015" imagem={fotoFachada2015} descricao="Mudança de endereço da filial de Santa Catarina, agora localizada na Av. Ivo Lucchi, 207 – no município de Palhoça. Contamos com 9 colaboradores diretos, sendo 5 vendedores em contato diário com nossos clientes."></ItemLinhaDoTempoInstitucional>
          <ItemLinhaDoTempoInstitucional semLinha={true} mobile={mobile} ano="2018" imagem={fotoFachada2018} descricao="Reestruturação da loja Giros Peças, localizada na Av. Cairu 1045.  Contamos hoje com aproximadamente 50 colaboradores diretos, 14 vendedores em contato diário com nossos clientes, um estoque de mais de 50 mil itens ativos."></ItemLinhaDoTempoInstitucional>
        </div>
        {!mobile ?
          <div className='divDoBanner3Institucional'>
            <img src={banner3Institucional} alt='Banner escrito: Giros 33 anos, o que move a giros a 33 anos é a paixão por pessoas!'></img>
          </div>
          : null}
        <div className='divResponsabilidadeAmbiental'>
          <h1 className='tituloReponsabilidadeSocial'>RESPONSABILIDADE SOCIAL E AMBIENTAL</h1>
          <div className='divMaiorDaResponsabilidadeSocialInstitucional'>
            <div>
              <div className='divTextoResponsabilidadeAmbiental'>
                <p>
                  <b>Sustentabilidade</b>
                  Separação correta de lixo, reciclagem de materiais e pontos de coleta para pilhas, baterias e óleo de cozinha.
                </p>
              </div>
              <div className='divTextoResponsabilidadeAmbiental'>
                <p>
                  <b>Ações Solidárias</b>
                  Doações de roupas e mantimentos para entidades carentes.
                </p>
              </div>
              {mobile ? <img style={{ width: '100%', marginTop: '2em' }} src={imagemGirosFreteNeutro} alt='Imagem informando que a giros agora é frete neutro'></img> : null}
              <h2 className='textoImportanteResponsabilidadeAmbiental'>A partir de 2024, todas as emissões de carbono feitas nas entregas da Giros serão compensadas em ações em prol da natureza.</h2>
              <div className='textosInformativosFreteNeutro'>
                <p style={{ marginBottom: !mobile ? "1em" : "0em" }}><b>Somos certificados de neutralização</b></p>
                <p style={{ marginTop: !mobile ? "1em" : "0em" }}>Através de um aplicativo, todas as nossas corridas para entregas são reportadas e tem sua emissão de carbono compensada. Como? por meio de apoio a projetos de preservação ambiental certificados pelo Protocolo de Kyoto.</p>
                <p>Estamos fazendo parte do Projeto Frete Neutro, uma iniciativa Eccaplan Consultoria em Sustentabilidade, com o apoio do Ministério da Ciência & Tecnologia da USP.</p>
                <p>Esse foi um meio que encontramos para amenizar os efeitos da queima de combustível, preservando a natureza. Saiba mais sobre essa iniciativa em <a target='_blank' rel='noopener noreferrer' href='https://eccaplan.com.br'>eccaplan.com.br</a></p>
              </div>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '4em' }}>
              {!mobile ? <img src={imagemResponsabilidadeAmbiental} alt='imagem representando a responsabilidade ambiental e social da giros'></img> : null}
              {!mobile ? <img src={imagemGirosFreteNeutro} alt='Imagem informando que a giros agora é frete neutro'></img> : null}
            </div>
          </div>
        </div>
        <h2 className='tituloResultadoEmNumeros'>NOSSOS RESULTADOS EM NÚMEROS</h2>
        <div className='divMaiorResultadoEmNumeros'>
          <div className='itemResultadoEmNumeros' style={{ backgroundImage: `url(${imagem33anos})`, padding: '7rem 3rem 4rem 3rem', width: 'calc(35% - 6rem)' }} alt='Crescimento da empresa'>
            <h3 className='tituloImagemResultadoEmNumeros'>
              Fazendo o mercado girar
              <label>há mais de 33 anos</label>
            </h3>
          </div>
          <div className='itemResultadoEmNumeros'>
            <div><img src={iconItensCadastrados} alt=''></img></div>
            <h3>60 mil</h3>
            <h4>Itens cadastrados</h4>
          </div>
          <div className='itemResultadoEmNumeros'>
            <div><img src={iconItensEmEstoque} alt=''></img></div>
            <h3>+ de 600mil</h3>
            <h4>peças em estoque</h4>
          </div>
        </div>
      </div>
      <BottomSitePublico></BottomSitePublico>
    </div >
  );
}

export default Institucional;