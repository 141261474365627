import './GarantiaForm.css';
import icon2 from './imgs/Blue.png';
import icon3 from './imgs/icon3unselect.png';
import icon4 from './imgs/icon4unselect.png';
import iconCheck from './imgs/iconOk.png';
import shape from './imgs/Shape.png';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AlertBox from '../../AlertBox';
import { Link } from 'react-router-dom';

const GarantiaForm = ({ itensSelecionados, notaFiscal }) => {
    const [mobile, setMobile] = useState(window.innerWidth <= 850);

    useEffect(() => {
        function handleResize() {
            setMobile(window.innerWidth <= 850);
        }

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const navigate = useNavigate();
    const currentYear = new Date().getFullYear();
    const [aplicada, setAplicada] = useState(null);
    const [veiculo, setVeiculo] = useState('');
    const [anoVeiculo, setAnoVeiculo] = useState('');
    const [motor, setMotor] = useState('');
    const [cilindros, setCilindros] = useState('');
    const [valvulas, setValvulas] = useState('');
    const [motivo, setMotivo] = useState('');
    const [descricaoProblema, setDescricaoProblema] = useState('');
    const [despesasAdicionais, setDespesasAdicionais] = useState(null);
    const [indiceItemSelecionado, setIndiceItemSelecionado] = useState(0);
    const [itensSelecionadosComMotivo, setItensSelecionadosComMotivo] = useState([]);
    const [alertBoxVisivel, setalertBoxVisivel] = useState(false);
    const urlCompleto = window.location.href;
    const partesUrl = urlCompleto.split('/');
    partesUrl.pop();
    const urlAnterior = partesUrl.join('/');

    useEffect(() => {
        if (!itensSelecionados.quantidadeSelecionada) {
            itensSelecionados.quantidadeSelecionada = itensSelecionados.quantidade;
        }
        if (itensSelecionadosComMotivo.length <= 0) {
            setItensSelecionadosComMotivo([...itensSelecionadosComMotivo, itensSelecionados]);
        }
    }, [itensSelecionados]);

    //Botões 'a peça foi aplicada?'
    const handleSimClick = () => {
        if (aplicada !== 'true') {
            const updatedItens = [...itensSelecionadosComMotivo];
            updatedItens[indiceItemSelecionado].produtoAplicado = 'true';
            setAplicada('true', () => {

            });
        }
    };

    const handleNaoClick = () => {
        const updatedItens = [...itensSelecionadosComMotivo];
        updatedItens[indiceItemSelecionado].produtoAplicado = 'false';
        if (aplicada !== 'false') {
            setAplicada('false');
            setVeiculo('');
            updatedItens[indiceItemSelecionado].veiculo = null;
            setAnoVeiculo('');
            updatedItens[indiceItemSelecionado].anoVeiculo = null;
            setMotor('');
            updatedItens[indiceItemSelecionado].motor = null;
            setCilindros('');
            updatedItens[indiceItemSelecionado].cilindros = null;
            setValvulas('');
            updatedItens[indiceItemSelecionado].valvulas = null;
        }
    };

    const proximaPagina = () => {
        const todosItensValidos = itensSelecionadosComMotivo.every(item => {
            if (item.produtoAplicado === 'true') {
                return (
                    item.veiculo &&
                    item.anoVeiculo &&
                    item.motor &&
                    item.cilindros &&
                    item.valvulas &&
                    item.motivo &&
                    item.descricaoProblema &&
                    item.despesasAdicionais !== null
                );
            } else if (item.produtoAplicado === 'false') {
                return (
                    item.aplicada !== null &&
                    item.motivo &&
                    item.descricaoProblema
                );
            } else {
                return false;
            }
        });

        if (todosItensValidos) {
            setalertBoxVisivel(false);
            navigate('enviarNotaDeGarantia', { state: { itensSelecionadosComMotivo, notaFiscal } });
        } else {
            setalertBoxVisivel(true);
            setTimeout(() => {
                setalertBoxVisivel(false);
            }, 5000);
        }
    };

    //handle para salvar os itens
    const handleDespesasAdicionais = (despesasValue) => {
        const updatedItens = [...itensSelecionadosComMotivo];
        updatedItens[indiceItemSelecionado].despesasAdicionais = despesasValue;
        setDespesasAdicionais(despesasValue);
    };
    const handleMotivoChange = (event) => {
        const updatedItens = [...itensSelecionadosComMotivo];
        updatedItens[indiceItemSelecionado].motivo = event.target.value;
        setMotivo(event.target.value);
    };
    const handleDescricaoProblema = (event) => {
        const updatedItens = [...itensSelecionadosComMotivo];
        updatedItens[indiceItemSelecionado].descricaoProblema = event.target.value;
        setDescricaoProblema(event.target.value);
    };
    const handleVeiculoAplicado = (event) => {
        const updatedItens = [...itensSelecionadosComMotivo];
        updatedItens[indiceItemSelecionado].veiculo = event.target.value;
        setVeiculo(event.target.value);
    };
    const handleAnoVeiculo = (event) => {
        const updatedItens = [...itensSelecionadosComMotivo];
        updatedItens[indiceItemSelecionado].anoVeiculo = event.target.value;
        setAnoVeiculo(event.target.value);
    };
    const handleMotor = (event) => {
        const updatedItens = [...itensSelecionadosComMotivo];
        updatedItens[indiceItemSelecionado].motor = event.target.value;
        setMotor(event.target.value);
    };
    const handleCilindros = (event) => {
        const updatedItens = [...itensSelecionadosComMotivo];
        updatedItens[indiceItemSelecionado].cilindros = event.target.value;
        setCilindros(event.target.value);
    };
    const handleValvulas = (event) => {
        const updatedItens = [...itensSelecionadosComMotivo];
        updatedItens[indiceItemSelecionado].valvulas = event.target.value;
        setValvulas(event.target.value);
    };

    //trocar os valores quando trocar de item
    const handleItemClick = (index) => {
        setIndiceItemSelecionado(index);
        const selectedMotivo = itensSelecionadosComMotivo[index]?.motivo || '';
        const selectedDescricao = itensSelecionadosComMotivo[index]?.descricaoProblema || '';
        const selectedVeiculo = itensSelecionadosComMotivo[index]?.veiculo || '';
        const selectedAnoVeiculo = itensSelecionadosComMotivo[index]?.anoVeiculo || '';
        const selectedMotor = itensSelecionadosComMotivo[index]?.motor || '';
        const selectedCilindros = itensSelecionadosComMotivo[index]?.cilindros || '';
        const selectedValvulas = itensSelecionadosComMotivo[index]?.valvulas || '';
        const selectedDespesasAdicionais = itensSelecionadosComMotivo[index]?.despesasAdicionais || '';
        const selectedAplicada = itensSelecionadosComMotivo[index]?.produtoAplicado || '';

        setDescricaoProblema(selectedDescricao);
        setMotivo(selectedMotivo);
        setVeiculo(selectedVeiculo);
        setAnoVeiculo(selectedAnoVeiculo);
        setMotor(selectedMotor);
        setCilindros(selectedCilindros);
        setValvulas(selectedValvulas);
        setDespesasAdicionais(selectedDespesasAdicionais);
        setAplicada(selectedAplicada);
    };
    return (
        <div className='garantiaDivMaiorClasseParaMobile' style={{ display: "flex", justifyContent: "center" }}>
            <AlertBox visivel={alertBoxVisivel} texto='Atenção! Você deve preencher todos os itens para continuar' />
            {mobile ? (
                <div style={{ display: 'flex', gap: '1em', flexDirection: 'column', marginTop: '1em', width: '100%' }}>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginLeft: '5%' }}>
                        <div className='indicacaoDeEtapaAtualNumeroMobile'>2</div>
                        <label className='labelDoIndicacaoDeEtapaAtualNumeroMobile'>Informações gerais dos itens</label>
                    </div>
                    <div className='barraMaiorIndicacaoMobile'>
                        <div style={{ width: '40%' }} className='barraMenorIndicacaoMobile' />
                    </div>
                </div>
            ) : null}
            <div className='whiteBoxGar'>
                <h1 className='tituloDaWhiteBoxMobileDoFormGarantia'>
                    Novo Protocolo de Garantia
                </h1>
                {!mobile ? (
                    <div>
                        <div className='progressBar'>
                            <span className='progressFill'></span>
                        </div>
                        <div className='progressStage'>
                            <div className='stages'>
                                <img src={iconCheck} alt='' />
                                <h2>Seleção de Itens</h2>
                            </div>

                            <div className='stages'>
                                <h2 style={{ color: 'rgb(255, 255, 255)', zIndex: '2', bottom: '0.9px', left: '25.3px', position: 'relative' }}>2</h2>
                                <img src={icon2} alt='' />
                                <h2 style={{ fontWeight: 700, fontSize: 14, color: "#041E42" }}>Informações gerais</h2>
                            </div>

                            <div className='stages'>
                                <img src={icon3} alt='' />
                                <h2>Documentação</h2>
                            </div>

                            <div className='stages'>
                                <img src={icon4} alt='' />
                                <h2>Validação</h2>
                            </div>

                        </div>
                    </div>
                ) : null}
                <div className='anotherBox'>
                    <div className='leftSideGar'>
                        <label className='labelDoTituloDaListaParaMobile' style={{ marginLeft: '2lh' }}>Responda sobre o item selecionado:</label>
                        <ul className='listaDeItensParaMobile'>
                            {itensSelecionadosComMotivo.map((item, index) => (
                                <div
                                    className={`caixaDoItemSelecionado ${index === indiceItemSelecionado ? 'itemSelecionado' : ''}`}
                                    name={index}
                                    key={index}
                                    style={{ display: 'flex', flexDirection: 'column' }}
                                    onClick={() => handleItemClick(index)}>
                                    <li style={{ listStyle: 'none' }}>
                                        <div className='quantidadeTexto'>
                                            Quantidade selecionada: {item.quantidadeSelecionada}
                                        </div>
                                        <div className='idDaPecaTexto'>
                                            {item.codigoDaPeca}
                                        </div>
                                    </li>
                                </div>
                            ))}
                        </ul>
                    </div>
                    <hr className="verticalLineBox" />
                    <div className='rigthSideGar'>
                        <div className='rigthSideInsideGar'>
                            <h1>A peça foi aplicada?</h1>
                            <div className='Buttons'>
                                <button
                                    className={`topOnes ${aplicada === 'true' && 'active'}`}
                                    style={aplicada === 'true' ? { backgroundColor: '#005EB8', borderColor: '#005EB8', color: '#ffffff', fontWeight: 700 } : {}}
                                    onClick={handleSimClick}
                                >
                                    Sim
                                </button>
                                <button
                                    className={`topOnes ${aplicada === 'false' && 'active'}`}
                                    style={aplicada === 'false' ? { backgroundColor: '#005EB8', borderColor: '#005EB8', color: '#ffffff', fontWeight: 700 } : {}}
                                    onClick={handleNaoClick}
                                >
                                    Não
                                </button>
                            </div>
                            {aplicada !== null && aplicada === 'false' && (
                                <div className='anotherDiv'>
                                    <h1>Qual o motivo da solicitação de garantia?</h1>
                                    <div className="select-wrapper">
                                        <select className='choices'
                                            value={motivo}
                                            onChange={handleMotivoChange}
                                        >
                                            <option disabled value="">Selecionar motivo</option>
                                            <option value="Cliente_final_desistiu_da_compra">Cliente final desistiu da compra</option>
                                            <option value="Cliente_nao_usa_a_marca">Cliente não usa a marca</option>
                                            <option value="Cliente_pediu_modelo_errado">Cliente pediu modelo errado</option>
                                            <option value="Troca_de_medida">Troca de medida</option>
                                            <option value="Troca_de_modelo">Troca de modelo</option>
                                            <option value="Vendedor_enviou_peca_errada">Vendedor enviou peça errada</option>
                                        </select>
                                        <img src={shape} alt="Ícone" className="custom-arrow" />
                                    </div>
                                    <h1>Descrição do problema</h1>

                                    <textarea className='descricaoProblema'
                                        type="text"
                                        value={descricaoProblema}
                                        onChange={handleDescricaoProblema}
                                    ></textarea>
                                </div>
                            )}

                            {aplicada !== null && aplicada === 'true' && (
                                <div>

                                    <h1>Veículo aplicado</h1>
                                    <input className='veiculoAplicado'
                                        type="text"
                                        value={veiculo}
                                        onChange={handleVeiculoAplicado}
                                    />

                                    <h1>Ano do veículo</h1>
                                    <input className='veiculoAplicado'
                                        style={{ width: 'auto', pointerEvents: 'all' }}
                                        type="number"
                                        value={anoVeiculo}
                                        onChange={handleAnoVeiculo}
                                    />

                                    <h1>Motor</h1>
                                    <div className="select-wrapper">
                                        <select className='choices'
                                            value={motor}
                                            onChange={handleMotor}
                                        >
                                            <option disabled value="">Selecionar motor</option>
                                            <option value="opcao1">Motor a gasolina</option>
                                            <option value="opcao2">Motor a diesel</option>
                                            <option value="opcao3">Motor elétrico</option>
                                        </select>
                                        <img src={shape} alt="Ícone" className="custom-arrow" />
                                    </div>

                                    <h1>Cilindros</h1>
                                    <div className="select-wrapper">
                                        <select className='choices'
                                            value={cilindros}
                                            onChange={handleCilindros}
                                        >
                                            <option disabled value="">Selecionar cilindros</option>
                                            <option value="opcao1">4 cilindros</option>
                                            <option value="opcao2">6 cilindros</option>
                                            <option value="opcao3">8 cilindros</option>
                                        </select>
                                        <img src={shape} alt="Ícone" className="custom-arrow" />
                                    </div>
                                    <h1>Válvulas</h1>

                                    <div className="select-wrapper">
                                        <select className='choices'
                                            value={valvulas}
                                            onChange={handleValvulas}
                                        >
                                            <option disabled value="">Selecionar válvulas</option>
                                            <option value="opcao1">2 válvulas por cilindro</option>
                                            <option value="opcao2">4 válvulas por cilindro</option>
                                            <option value="opcao3">6 válvulas por cilindro</option>
                                        </select>
                                        <img src={shape} alt="Ícone" className="custom-arrow" />
                                    </div>

                                    <h1>Qual o motivo da solicitação de garantia?</h1>
                                    <div className="select-wrapper">
                                        <select className='choices'
                                            value={motivo}
                                            onChange={handleMotivoChange}
                                        >
                                            <option disabled value="">Selecionar motivo</option>
                                            <option value="Cliente_final_desistiu_da_compra">Cliente final desistiu da compra</option>
                                            <option value="Cliente_nao_usa_a_marca">Cliente não usa a marca</option>
                                            <option value="Cliente_pediu_modelo_errado">Cliente pediu modelo errado</option>
                                            <option value="Troca_de_medida">Troca de medida</option>
                                            <option value="Troca_de_modelo">Troca de modelo</option>
                                            <option value="Vendedor_enviou_peca_errada">Vendedor enviou peça errada</option>
                                        </select>
                                        <img src={shape} alt="Ícone" className="custom-arrow" />
                                    </div>

                                    <h1>Descrição do problema</h1>
                                    <textarea className='descricaoProblema'
                                        type="text"
                                        value={descricaoProblema}
                                        onChange={handleDescricaoProblema}
                                    ></textarea>

                                    <h1>Você teve despesas adicionais?</h1>
                                    <div className='Buttons'>
                                        <button
                                            className={`bottomOnes ${despesasAdicionais === 'true' ? 'active' : ''}`}
                                            style={despesasAdicionais === 'true' ? { backgroundColor: '#005EB8', borderColor: '#005EB8', color: '#ffffff', fontWeight: 700 } : {}}
                                            onClick={() => handleDespesasAdicionais('true')}
                                        >
                                            Sim
                                        </button>
                                        <button
                                            className={`bottomOnes ${despesasAdicionais === 'false' ? 'active' : ''}`}
                                            style={despesasAdicionais === 'false' ? { backgroundColor: '#005EB8', borderColor: '#005EB8', color: '#ffffff', fontWeight: 700 } : {}}
                                            onClick={() => handleDespesasAdicionais('false')}
                                        >
                                            Não
                                        </button>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>

                {!mobile ? (<div className='divBackFoward'>
                    <Link to={urlAnterior} style={{ width: 'fit-content', height: 'fit-content' }}>
                        <div className='buttonBack' style={{ cursor: 'pointer' }}>
                            <h1>❮</h1>
                            <h1>Voltar</h1>
                        </div>
                    </Link>
                    <div className='buttonFoward' style={{ cursor: 'pointer' }} onClick={proximaPagina}>
                        <h1>Prosseguir</h1>
                        <h1>❯</h1>
                    </div>
                </div>) : (
                    <div className='botoesInferiores' style={{position: 'fixed'}}>
                        <div className='botaoCancelar' onClick={() => window.history.back()}>Cancelar</div>
                        <div onClick={proximaPagina} style={{ width: 'fit-content', height: 'fit-content', cursor: 'pointer' }}>
                            <div className='botaoProsseguir'>
                                <label style={{ cursor: 'pointer' }}>Prosseguir</label>
                                <label style={{ cursor: 'pointer' }}>❯</label>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </div >
    )
}

export default GarantiaForm;