import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import "./DadosCadastrais.css";
import Header from '../../componentes/Header';
import Cookies from 'js-cookie';
import Checkbox from '../../componentes/Checkbox';

const DadosCadastrais = () => {
  const [nome, setNome] = useState("");
  const [cpf, setCpf] = useState("");
  const [cpfInicial, setCpfInicial] = useState("");
  const [email, setEmail] = useState("");
  const [nomeFantasia, setNomeFantasia] = useState("");
  const [inscricaoEstadual, setInscricaoEstadual] = useState("");
  const [cidade, setCidade] = useState("");
  const [unidadeFederativa, setUnidadeFederativa] = useState("");
  const [cep, setCep] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");

  const [telefone, setTelefone] = useState("");
  const [notificacoes, setNotificacoes] = useState(false);
  const [inputHabilitado, setInputHabilitado] = useState(false);
  const [textoDoBotaoEditarDados, setTextoDoBotaoEditarDados] = useState("Editar Dados");
  const navigate = useNavigate();

  const handleNotificacoesChange = () => {
    setNotificacoes(!notificacoes)
  }
  const handleEditarDados = async () => {
    if (inputHabilitado === false) {
      setInputHabilitado(true);
      setTextoDoBotaoEditarDados("Salvar dados");
    } else {
      try {
        const token = Cookies.get('token');

        if (token) {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/salvarDadosCadastrais`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({
              nome: nome || "",
              cpf: cpf || "",
              email: email || "",
              telefone: telefone || "",
              nomeFantasia: nomeFantasia || "",
              inscricaoEstadual: inscricaoEstadual || "",
              cidade: cidade || "",
              unidadeFederativa: unidadeFederativa || "",
              cep: cep || "",
              rua: rua || "",
              numero: numero || "",
              notificacoes: notificacoes,
            }),
          });
          if (response.ok) {
            if (cpfInicial !== cpf) {
              navigate("/login");
            } else {
              console.log("Salvo com sucesso")
            }
          } else {
            console.error('Erro');
          }
        }
      } catch (error) {
        console.error('Erro: ', error);
      }
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = new Headers({
            'Authorization': `Bearer ${token}`,
          });

          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/resgatarDadosCadastrais`, { headers });

          if (!response.ok) {
            throw new Error('Falha ao obter dados cadastrais');
          }
          const data = await response.json();
          setNome(data.nome || "");
          setCpf(data.cpf || "");
          setCpfInicial(data.cpf || "");
          setEmail(data.email || "");
          setTelefone(data.telefone || "");
          setNomeFantasia(data.nomeFantasia || "");
          setInscricaoEstadual(data.inscricaoEstadual || "");
          setCidade(data.cidade || "");
          setUnidadeFederativa(data.unidadeFederativa || "");
          setNotificacoes(data.notificacoes);
          setCep(data.cep || "");
          setRua(data.rua || "");
          setNumero(data.numero || "");
        }
      } catch (error) {
        console.error('Erro ao obter dados cadastrais:', error);
      }
    };

    fetchData();
  }, []);
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      const fetchData = async () => {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });
        } catch {
          setStatusUsuario({ logado: false, admin: false });
          navigate('/login');
        }
      };
      fetchData();
    } else {
      navigate('/login');
    }
  }, [navigate]);
  return (
    <div>
      <Header logado={statusUsuario.logado} admin={statusUsuario.admin} />
      <div className='conteudoDaPaginaDadosCadastrais'>
        <Link to='/historico'>
          <h1 className='botaoDeVoltar botaoDeVoltarDoDadosCadastrais'> ❮  Voltar</h1>
        </Link>
        <div className='divMaiorDadosCadastrais'>
          <h1>Dados Cadastrais</h1>
          <div className='divGeralDoDadosCadastrais'>
            <div className={'divDoInputDeDadosCadastrais' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
              <label>Nome ou Razão Social</label>
              <input type="text" value={nome} onChange={(e) => setNome(e.target.value)}></input>
            </div>
            <div className={'divDoInputDeDadosCadastrais' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
              <label>Nome Fantasia</label>
              <input type="text" value={nomeFantasia} onChange={(e) => setNomeFantasia(e.target.value)}></input>
            </div>
            <div className={'divDoInputDeDadosCadastrais' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
              <label>CPF ou CNPJ</label>
              <input type="number" value={cpf} onChange={(e) => setCpf(e.target.value)}></input>
            </div>
            <div className={'divDoInputDeDadosCadastrais' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
              <label>Inscrição Estadual</label>
              <input type="number" value={inscricaoEstadual} placeholder='Isento' onChange={(e) => setInscricaoEstadual(e.target.value)}></input>
            </div>
            <div className={'divDoInputDeDadosCadastrais' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
              <label>E-mail</label>
              <input type="text" value={email} onChange={(e) => setEmail(e.target.value)}></input>
            </div>
            <div className={'divDoInputDeDadosCadastrais' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
              <label>Telefone</label>
              <input type="number" value={telefone} onChange={(e) => setTelefone(e.target.value)}></input>
            </div>
            <div className={'divDoCheckBoxNotificacoes' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
              <Checkbox isChecked={notificacoes} onChange={handleNotificacoesChange}></Checkbox>
              <label>Aceito receber notificações por WhatsApp.</label>
            </div>
            <div className={'divDoInputDeDadosCadastrais' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
              <label>CEP</label>
              <input type="number" value={cep} onChange={(e) => setCep(e.target.value)}></input>
            </div>
            <div className={'divDoInputDeDadosCadastrais' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
              <label>Rua</label>
              <input type="text" value={rua} onChange={(e) => setRua(e.target.value)}></input>
            </div>
            <div className={'divDoInputDeDadosCadastrais' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
              <label>Cidade</label>
              <input type="text" value={cidade} onChange={(e) => setCidade(e.target.value)}></input>
            </div>
            <div className='divUnidFedNumero'>
              <div className={'divDoInputDeDadosCadastrais' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
                <label>UF</label>
                <input type="text" value={unidadeFederativa} onChange={(e) => setUnidadeFederativa(e.target.value)}></input>
              </div>
              <div className={'divDoInputDeDadosCadastrais' + (inputHabilitado ? '' : ' inputDeDadosDesabilitado')} >
                <label>Número</label>
                <input type="number" value={numero} onChange={(e) => setNumero(e.target.value)}></input>
              </div>
            </div>
            <div onClick={handleEditarDados} className='botaoEditarDados'>{textoDoBotaoEditarDados}</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DadosCadastrais;
