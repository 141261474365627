import { BrowserRouter, Route, Routes } from "react-router-dom";
import Cadastro from "./pages/Cadastro";
import Historico from "./pages/Historico";
import ListaDeProtocolos from "./pages/ListaDeProtocolos";
import Login from "./pages/Login";
import PageDetalharNotaFiscal from "./pages/PageDetalharNotaFiscal";
import AbrirNovoProtocoloPage from "./pages/AbrirNovoProtocoloPage";
import SelecionarNotaOuCupomPage from "./pages/SelecionarNotaOuCupomPage";
import SelecionarItensNotaFiscalPage from "./pages/SelecionarItensNotaFiscalPage";
import MotivoProtocoloDevolucaoPage from "./pages/MotivoProtocoloDevolucaoPage";
import EnviarComprovanteDevolucaoPage from "./pages/EnviarComprovanteDevolucaoPage";
import GarantiaForm from "./pages/Garantia";
import ProtocoloCriadoPage from "./pages/ProtocoloCriadoPage";
import EnviarNotaGarantiaPage from "./pages/EnviarNotaGarantiaPage";
import EnviarComprovanteGastosGarantiaPage from "./pages/EnviarComprovanteGastosGarantiaPage";
import DetalharProtocolo from "./pages/DetalharProtocolo";
import NotificacoesPage from "./pages/NotificacoesPage";
import EsqueciMinhaSenha from "./pages/EsqueciMinhaSenha";
import CriarNovaSenha from "./pages/CriarNovaSenha";
import DadosCadastrais from "./pages/DadosCadastrais";
import SelecionarItensNotaFiscalGarantia from "./pages/SelecionarItensNotaFiscalGarantia";

import SitePublico from "./pages/AreaPublica/SitePublico";
import Contato from "./pages/AreaPublica/Contato";
import TrabalheConosco from "./pages/AreaPublica/TrabalheConosco";
import Institucional from "./pages/AreaPublica/Institucional";
import Conteudos from "./pages/AreaPublica/Conteudos";
import Produtos from "./pages/AreaPublica/Produtos";
import DetalharMarcaSitePublico from "./pages/AreaPublica/DetalharMarcaSitePublico";
import DetalharProdutoSitePublico from "./pages/AreaPublica/DetalharProdutoSitePublico";

import HomeAdmin from "./pages/AreaAdmin/HomeAdmin";
import ListagemUsuariosAdmin from "./pages/AreaAdmin/ListagemUsuariosAdmin";
import BannersPopupsAdmin from "./pages/AreaAdmin/BannersPopupsAdmin";
import ListagemMarcasAdmin from "./pages/AreaAdmin/ListagemMarcasAdmin";
import ListagemLinhasAdmin from "./pages/AreaAdmin/ListagemLinhasAdmin";
import ListagemProdutosAdmin from "./pages/AreaAdmin/ListagemProdutosAdmin";
function AppRoutes() {
    return (
        <BrowserRouter>
            <Routes>
                
                <Route path="/" element={<SitePublico />}>  </Route>
                <Route path="/contato" element={<Contato />}>  </Route>
                <Route path="/trabalheConosco" element={<TrabalheConosco />}>  </Route>
                <Route path="/institucional" element={<Institucional />}>  </Route>
                <Route path="/conteudos" element={<Conteudos />}>  </Route>

                <Route path="/produtos" element={<Produtos />}>  </Route>
                <Route path="/detalharMarcaSitePublico/:idMarca" element={<DetalharMarcaSitePublico />}>  </Route>
                <Route path="/detalharProdutoSitePublico/:idProduto" element={<DetalharProdutoSitePublico />}>  </Route>
                
                <Route path="/admin" element={<HomeAdmin />}>  </Route>
                <Route path="/admin/listagemUsuarios" element={<ListagemUsuariosAdmin />}>  </Route>
                <Route path="/admin/bannersPopups" element={<BannersPopupsAdmin />}>  </Route>
                <Route path="/admin/listagemMarcas" element={<ListagemMarcasAdmin />}>  </Route>
                <Route path="/admin/listagemLinhas" element={<ListagemLinhasAdmin />}>  </Route>
                <Route path="/admin/listagemProdutos" element={<ListagemProdutosAdmin />}>  </Route>

                <Route path="/login" element={<Login />}>  </Route>
                <Route path="/cadastro" element={<Cadastro />}>  </Route>
                <Route path="/historico" element={<Historico />}> </Route>
                <Route path="/listaDeProtocolos" element={<ListaDeProtocolos />}> </Route>
                <Route path="/detalharNotaFiscal/:numeroDaNota" element={<PageDetalharNotaFiscal />}></Route>
                <Route path="/detalharProtocolo/:idDoProtocolo" element={<DetalharProtocolo />}></Route>
                <Route path="/AbrirNovoProtocolo" element={<AbrirNovoProtocoloPage />}></Route>
                <Route path="/ListarNotificacoes" element={<NotificacoesPage />}></Route>
                <Route path="/EsqueciMinhaSenha" element={<EsqueciMinhaSenha />}></Route>
                <Route path="/CriarNovaSenha" element={<CriarNovaSenha />}></Route>
                <Route path="/DadosCadastrais" element={<DadosCadastrais />}></Route>

                <Route path="/AbrirNovoProtocolo/garantia" element={<SelecionarNotaOuCupomPage />}></Route>
                <Route path="/AbrirNovoProtocolo/garantia/selecionarNotaFiscal/:numeroDaNota" element={<SelecionarItensNotaFiscalGarantia />}></Route>
                <Route path="/AbrirNovoProtocolo/garantia/selecionarNotaFiscal/:numeroDaNota/motivoGarantia" element={<GarantiaForm />}></Route>
                <Route path="/AbrirNovoProtocolo/garantia/selecionarNotaFiscal/:numeroDaNota/motivoGarantia/enviarNotaDeGarantia" element={<EnviarNotaGarantiaPage />}></Route>
                <Route path="/AbrirNovoProtocolo/garantia/selecionarNotaFiscal/:numeroDaNota/motivoGarantia/enviarNotaDeGarantia/enviarComprovante" element={<EnviarComprovanteGastosGarantiaPage />}></Route>
                <Route path="/AbrirNovoProtocolo/garantia/selecionarNotaFiscal/:numeroDaNota/motivoGarantia/enviarNotaDeGarantia/enviarComprovante/protocoloCriado" element={<ProtocoloCriadoPage />}></Route>
                <Route path="/AbrirNovoProtocolo/garantia/selecionarNotaFiscal/:numeroDaNota/motivoGarantia/enviarNotaDeGarantia/protocoloCriado" element={<ProtocoloCriadoPage />}></Route>


                <Route path="/AbrirNovoProtocolo/devolucao" element={<SelecionarNotaOuCupomPage />}></Route>
                <Route path="/AbrirNovoProtocolo/devolucao/selecionarNotaFiscal/:numeroDaNota" element={<SelecionarItensNotaFiscalPage />}></Route>
                <Route path="/AbrirNovoProtocolo/devolucao/selecionarNotaFiscal/:numeroDaNota/motivoDevolucao" element={<MotivoProtocoloDevolucaoPage />}></Route>
                <Route path="/AbrirNovoProtocolo/devolucao/selecionarNotaFiscal/:numeroDaNota/motivoDevolucao/enviarComprovante" element={<EnviarComprovanteDevolucaoPage />}></Route>
                <Route path="/AbrirNovoProtocolo/devolucao/selecionarNotaFiscal/:numeroDaNota/motivoDevolucao/enviarComprovante/protocoloCriado" element={<ProtocoloCriadoPage />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default AppRoutes;