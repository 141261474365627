import React, { useEffect, useState } from 'react';

const UltimosPostsInstagram = () => {
  const [postagensInstagram, setPostagensInstagram] = useState([]);

  // Requisitar postagens mais recentes do Instagram
  useEffect(() => {
    const fetchInstagramPosts = async () => {
      const token = 'IGQWRQeTJ3OGFKQkpra3dLbnFROXRaYllkTF9Ddl84cjFXZAUlsTm85MG9CSzA3elkwOGZAwMnV3R0VjWjBmdV8wYjZAXUkhaMTl4X1d6WERiYVBmNHRQVDhNdTlqc2hlWTZA6TlJlNG5xdzZAfZA3RVUUFYd203TkE0TjAZD';
      const url = `https://graph.instagram.com/me/media?fields=id,caption,media_type,media_url,thumbnail_url,permalink&access_token=${token}`;
      
      try {
        const response = await fetch(url);
        const data = await response.json();
        
        // Formatar as postagens conforme o necessário
        const formattedPosts = data.data.map((post) => ({
          id: post.id,
          media_type: post.media_type,
          media_url: post.media_type === 'VIDEO' ? post.thumbnail_url : post.media_url,
          permalink: post.permalink,
          caption: post.caption || 'Sem legenda',
        }));
        setPostagensInstagram(formattedPosts.slice(0, 4));
      } catch (error) {
        console.error('Erro ao buscar postagens do Instagram:', error);
      }
    };

    fetchInstagramPosts();
  }, []);

  return (
    <>
      {postagensInstagram && (
        <div className="postsInstagram">
          {postagensInstagram.map((post) => (
            <a
              style={{ display: 'contents' }}
              key={post.id}
              href={post.permalink}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div className="itemPostInstagram">
                <img src={post.media_url} alt={post.caption} />
              </div>
            </a>
          ))}
        </div>
      )}
    </>
  );
};

export default UltimosPostsInstagram;