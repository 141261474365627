
import Header from '../../componentes/Header';
import './AbrirNovoProtocoloPage.css';
import BotaoDeVoltar from '../../componentes/BotaoDeVoltar';
import { Link } from 'react-router-dom';
import imagemDevolucao from './imgs/ImagemDevolucaoCard.png'
import imagemGarantia from './imgs/ImagemGarantiaCard.png'
function AbrirNovoProtocoloPage() {
  return (
    <div className="AbrirNovoProt">
      <div className="content">
        <Header logado={true} />
        <div style={{ display: "flex", justifyContent: "center" }}>
          <BotaoDeVoltar link="/historico" text=" ❮  Voltar" className="botaoVoltar2"></BotaoDeVoltar>
          <div className='retanguloDoMeioDaTela retanguloDoAbrirNovoProtocolo'>
            <h1 className='tituloAbrirNovoProtocolo'>Abrir novo protocolo</h1>
            <h3 className='subtituloAbrirNovoProtocolo'>Selecione o tipo de protocolo que deseja criar</h3>
            <div className='divAuxiliarDoAbrirNovoProtocoloPage' style={{ display: "flex", justifyContent: "space-around" }}>
              <Link to="/abrirNovoProtocolo/devolucao" style={{ height: 'fit-content', width: 'fit-content', textDecoration: 'none' }}>
                <div className='botaoProtocoloDevolucao'>
                  <div style={{ marginRight: "1lh" }}>
                    <img src={imagemDevolucao} alt="Imagem devolução" className='imagemCard' />
                  </div>
                  <div>
                    <h3 className='tituloDoCard'>Protocolo de Devolução</h3>
                    <label className='textoDoCard'>Para solicitar devolução ou troca de produtos que não foram aplicados ou utilizados.</label>
                  </div>
                </div>
              </Link>
              <Link to="/abrirNovoProtocolo/garantia"  style={{ height: 'fit-content', width: 'fit-content', textDecoration: 'none' }}>
                <div className='botaoProtocoloGarantia'>
                  <div style={{ marginRight: '-3lh', width: '21lh' }}>
                    <img src={imagemGarantia} alt="Imagem garantia" className='imagemCard classeParaFormatarAImagemMobileAbrirProtocolo' style={{ width: "40%", height: "40%" }} />
                  </div>
                  <div className='paddingLeftDe1em'>
                    <h3 className='tituloDoCard'>Protocolo de Garantia</h3>
                    <label className='textoDoCard'>Para produtos aplicados, ou não aplicados mas com embalagem danificada, peça incompatível com etiqueta, quebrada, danificada ou oxidada (dentro da embalagem).</label>
                  </div>
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default AbrirNovoProtocoloPage;