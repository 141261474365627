import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import './AlertBox.css';
import iconAlert from './imgs/iconAlert.png';

const AlertBox = ({ visivel, texto }) => {
  const alertBoxRef = useRef(null);

  useEffect(() => {
    const alertBox = alertBoxRef.current;

    if (visivel) {
      alertBox.classList.add('shake-animation');
    } else {
      alertBox.classList.remove('shake-animation');
    }

    const onAnimationEnd = () => {
      if (!visivel) {
        alertBox.classList.add('hidden');
      }
    };

    alertBox.addEventListener('animationend', onAnimationEnd);

    return () => {
      alertBox.removeEventListener('animationend', onAnimationEnd);
    };
  }, [visivel]);

  const estiloVisibilidade = { display: visivel ? 'flex' : 'none' };

  // Use classNames para condicionalmente adicionar as classes de animação e visibilidade
  const alertBoxClasses = classNames('alert-box', {
    'shake-animation': visivel,
    'hidden': !visivel,
  });

  return (
    <div className={alertBoxClasses} style={estiloVisibilidade} ref={alertBoxRef}>
      <img style={{ width: '28px', height: '28px' }} src={iconAlert} alt="Icone de alerta" />
      <h1 className="alert-box-h1">{texto}</h1>
    </div>
  );
};

export default AlertBox;
