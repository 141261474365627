import React, { useState, useEffect } from 'react';
import './LinhaDoTempoProtocolo.css';
import certinhoAzul from './imgs/CertinhoAzul.svg';
import circuloVerde from './imgs/CirculoVerde.svg';
import circuloCinza from './imgs/CirculoCinza.svg';

const LinhaDoTempoProtocolo = ({ status }) => {
    const [larguraBarraAzul, setLarguraBarraAzul] = useState('0%');
    const [larguraBarraCinza, setLarguraBarraCinza] = useState('100%');
    useEffect(() => {
        if (status === "PROTOCOLO_EM_ANALISE") {
            setLarguraBarraAzul("0%");
            setLarguraBarraCinza("95%");
        } else if (status === "PROTOCOLO_PREENCHIDO") {
            setLarguraBarraAzul("20%");
            setLarguraBarraCinza("80%");
        } else if (status === "NOTA_VALIDADA") {
            setLarguraBarraAzul("30%");
            setLarguraBarraCinza("70%");
        } else if (status === "ENVIO_AUTORIZADO") {
            setLarguraBarraAzul("57%");
            setLarguraBarraCinza("39%");
        } else if (status === "LAUDO_APROVADO" || status === "LAUDO_REPROVADO") {
            setLarguraBarraAzul("75%");
            setLarguraBarraCinza("20%");
        } else if (status === "ENCERRADO") {
            setLarguraBarraAzul("95%");
            setLarguraBarraCinza("0%");
        }
    }, [status]);

    if (!status) {
        status = "PROTOCOLO_EM_ANALISE";
    }
    const images = {
        "PROTOCOLO_EM_ANALISE": [circuloVerde, circuloCinza, circuloCinza, circuloCinza, circuloCinza, circuloCinza],
        "PROTOCOLO_PREENCHIDO": [certinhoAzul, circuloVerde, circuloCinza, circuloCinza, circuloCinza, circuloCinza],
        "NOTA_VALIDADA": [certinhoAzul, certinhoAzul, circuloVerde, circuloCinza, circuloCinza, circuloCinza],
        "ENVIO_AUTORIZADO": [certinhoAzul, certinhoAzul, certinhoAzul, circuloVerde, circuloCinza, circuloCinza],
        "LAUDO_APROVADO": [certinhoAzul, certinhoAzul, certinhoAzul, certinhoAzul, circuloVerde, circuloCinza],
        "LAUDO_REPROVADO": [certinhoAzul, certinhoAzul, certinhoAzul, certinhoAzul, circuloVerde, circuloCinza],
        "ENCERRADO": [certinhoAzul, certinhoAzul, certinhoAzul, certinhoAzul, certinhoAzul, circuloVerde]
    };

    const imgs = images[status];
    const labels = ["Protocolo Criado", "Protocolo Preenchido", "Nota validada", "Envio autorizado", "Peças conferidas", "Créditos liberados"];

    return (
        <div>
            <div className="linhaAzulDaLinhaDoTempo">
                <div className="progressoLinhaAzulDaLinhaDoTempo" style={{ width: larguraBarraAzul }}></div>
            </div>
            <div className="linhaPontilhadaDaLinhaDoTempo">
                <div className="progressoLinhaPontilhadaDaLinhaDoTempo" style={{ width: larguraBarraCinza }}></div>
            </div>
            <ul className='linhaDoTempoDoProtocolo'>
                {labels.map((label, index) => (
                    <li key={label} style={{zIndex: '30'}}>
                        <div className='itemLinhaDoTempo'>
                            {imgs[index] === circuloVerde || imgs[index] === circuloCinza ? (
                                <div style={{ position: "relative" }}>
                                    <img src={imgs[index]} alt={label}></img>
                                    <span className='numeroDentroDoCirculo' style={{color: imgs[index] === circuloVerde ? 'white' : '#6B6B6B'}}>{index + 1}</span>
                                </div>
                            ) : (
                                <div style={{ position: "relative" }}>
                                    <img src={imgs[index]} alt={label}></img>
                                    <span></span>
                                </div>
                            )}
                            <label>{label}</label>
                        </div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default LinhaDoTempoProtocolo;
