import Header from '../../componentes/Header';
import './Login.css';
import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CampoTexto from '../../componentes/CampoTexto';
import iconeX from './iconeX.png';
import Cookies from 'js-cookie';
function Login() {
  const navigate = useNavigate();
  const [esqueciMinhaSenha, setEsqueciMinhaSenha] = useState(false);
  const [emailEscrito, setEmailEscrito] = useState("");

  const [form, setForm] = useState({
    login: '',
    senha: '',
  });
  useEffect(() => {
    Cookies.set('token', '');
  },[])
  const handleChange = ({ name, value }) => {
    setForm({ ...form, [name]: value });
  }
  const handleChangeEmailEsqueciSenha = (event) => {
    setEmailEscrito(event.target.value);
  }
  const esqueciMinhaSenhaHandle = () => {
    setEsqueciMinhaSenha(!esqueciMinhaSenha);
  }
  const confirmarEmail = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/confirmarEmail`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: emailEscrito }),
      });
      if (response.ok) {
        const confirmacao = await response.json();
        if (confirmacao === true) {
          navigate("/esqueciMinhaSenha", { state: { emailEscrito } });
        } else {
          console.log("Este email não corresponde a nenhuma conta em nosso sistema.");
        }
      } else {
        console.error('Erro');
      }
    } catch (error) {
      console.error('Erro: ', error);
    }
  };
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(form),
      });
      if (response.ok) {
        const data = await response.json();
        Cookies.set('token', data.token);
        document.querySelector('.divFormulario2 input[name="login"]').style.border = '2px solid black';
        document.querySelector('.divFormulario2 input[name="senha"]').style.border = '2px solid black';
        document.querySelector('.advertenciaDeSenha[name="advertenciaDeSenha"]').style.display = 'none';
        navigate("/historico")
      } else {
        console.error('Login falhou');
        document.querySelector('.divFormulario2 input[name="login"]').style.border = '2px solid red';
        document.querySelector('.divFormulario2 input[name="senha"]').style.border = '2px solid red';
        document.querySelector('.advertenciaDeSenha[name="advertenciaDeSenha"]').style.display = 'flex';

      }
    } catch (error) {
      console.error('Erro: ', error);
    }
  };
  return (
    <div className="Login">
      <Header />
      <div style={esqueciMinhaSenha ? { display: "flex" } : { display: "none" }} className='esqueciMinhaSenhaFundo'>
        <div className='esqueciMinhaSenhaPopUp'>
          <h3 className='esqueceuSuaSenhaText'>Esqueceu sua senha?</h3>
          <label className='semProblemasEmailText'>Sem problemas! Insira seu e-mail de cadastro, vamos enviar as instruções para você criar uma nova.</label>
          <div className='divDoInputDeEmail'>
            <label>E-mail</label>
            <input type="text" onChange={handleChangeEmailEsqueciSenha}></input>
          </div>
          <div onClick={confirmarEmail} className='enviarInstrucoesBotao'>Enviar as instruções</div>
          <p className='voltarAoLoginText' onClick={esqueciMinhaSenhaHandle}>Voltar ao login</p>
        </div>
      </div>
      <div className="containerFormulario">
        <div className="divFormulario1">
            <h1>Quero me cadastrar</h1><br />
            <p>É muito fácil, crie sua conta em apenas alguns minutos e gerencie suas solicitações.</p><br />
            <Link style={{width: '100%'}} to="/Cadastro">
              <button className='button2Formulario'>Criar nova conta</button>
            </Link>
        </div>
        <hr className="verticalLine" />
        <div className="divFormulario2">
            <h1>Já sou cliente</h1>
            <h2>Seja bem-vindo ao site da Giros</h2>
            <form onSubmit={handleSubmit} className='formFormulario'>
              <CampoTexto
                label="CPF ou CNPJ"
                type="text"
                name="login"
                onChange={handleChange}
              />
              <br />
              <CampoTexto
                label="Senha"
                type="password"
                name="senha"
                onChange={handleChange}
              />
              <div name="advertenciaDeSenha" className='advertenciaDeSenha'>
                <img alt='' src={iconeX}></img>
                <label>Número de CPF/CNPJ ou senha incorretos.</label>
              </div>
              <button type="submit" className='button1Formulario'>Entrar na minha conta</button>
            </form>
            <p className='linkFormulario' onClick={esqueciMinhaSenhaHandle}>Esqueci a senha</p>
        </div>
      </div>
    </div>
  );
}

export default Login;