import { useEffect, useState } from 'react';
import Header from '../../componentes/Header';
import './Notificacoes.css';
import Cookies from 'js-cookie';
import BotaoDeVoltar from '../../componentes/BotaoDeVoltar';
import setaParaBaixo from './SetaParaBaixo.svg';

function NotificacoesPage() {
  const [notificacoes, setNotificacoes] = useState([]);
  const [opcaoOrdenacao, setOpcaoOrdenacao] = useState('Data');
  const [ordenarPorDataAscendente, setOrdenarPorDataAscendente] = useState(true);

  const formatarData = (data) => {
    const dataFormatada = new Date(data);
    const dia = dataFormatada.getDate().toString().padStart(2, '0');
    const mes = (dataFormatada.getMonth() + 1).toString().padStart(2, '0');
    const ano = dataFormatada.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = new Headers({
            'Authorization': `Bearer ${token}`,
          });
          const response = await fetch(`${process.env.REACT_APP_API_URL}/notificacoes/listar`, { headers });
          const data = await response.json();

          const notificacoesFormatadas = data.map((notificacao) => ({
            ...notificacao,
            dataNotificacao: formatarData(notificacao.dataNotificacao),
          }));

          setNotificacoes(notificacoesFormatadas);
        }
      } catch (error) {
        console.error('Erro ao obter notificações:', error);
      }
    };

    fetchData();
  }, []);

const ordenarNotificacoes = (notificacoes, opcaoOrdenacao, ordenarPorDataAscendente) => {
  const copiaNotificacoes = [...notificacoes];
  if (opcaoOrdenacao === 'Data') {
    return copiaNotificacoes.sort((a, b) => {
      const dataA = new Date(a.dataNotificacao);
      const dataB = new Date(b.dataNotificacao);
      return ordenarPorDataAscendente ? dataA - dataB : dataB - dataA;
    });
  } else if (opcaoOrdenacao === 'Título') {
    return copiaNotificacoes.sort((a, b) => {
      return ordenarPorDataAscendente ? a.titulo.toLowerCase() > b.titulo.toLowerCase() ? 1 : -1 : b.titulo.toLowerCase() > a.titulo.toLowerCase() ? 1 : -1;
    });
  } else if (opcaoOrdenacao === 'ID') {
    return copiaNotificacoes.sort((a, b) => {
      return ordenarPorDataAscendente ? a.protocoloId - b.protocoloId : b.protocoloId - a.protocoloId;
    });
  }
};

useEffect(() => {
  const notificacoesOrdenadas = ordenarNotificacoes(notificacoes, opcaoOrdenacao, ordenarPorDataAscendente);
  setNotificacoes(notificacoesOrdenadas);
}, [opcaoOrdenacao, ordenarPorDataAscendente]);

  const toggleOrdenacao = () => {
    setOpcaoOrdenacao((prevOpcao) => {
      if (prevOpcao === 'Data') return 'Título';
      if (prevOpcao === 'Título') return 'ID';
      return 'Data';
    });
  };

  return (
    <div className="HistoricoDeCompras">
      <div className="content">
        <Header logado={true} />
        <div className='conteudoDaPaginaInteira'>
          <BotaoDeVoltar link="/historico" text=" ❮  Voltar" className="botaoVoltar2"></BotaoDeVoltar>
          <div style={{display: 'flex', justifyContent: 'space-between', width: '100%'}}>
            <h1 className='notificacoesTituloPagina'>Notificações</h1>
            <div style={{cursor: 'pointer' ,display: 'flex', alignItems: 'center', gap: '0.5em'}} onClick={toggleOrdenacao}>
              <p className='ordenarPor'>Ordenar por: {opcaoOrdenacao}</p>
              <img alt='' src={setaParaBaixo} style={{ transform: ordenarPorDataAscendente ? 'rotate(0deg)' : 'rotate(180deg)' }} />
            </div>
          </div>
          <ul className='listaDeNotificacoes'>
            {notificacoes.map((notificacao, index) => (
              <li key={index} className="itemNotificacao">
                <div className='notificacaoLinha1'>
                  <p className='tipoProtocoloNotificacao'>Protocolo {notificacao.tipoProtocolo} n° {notificacao.protocoloId}</p>
                  <p className='dataProtocoloNotificacao'>{notificacao.dataNotificacao}</p>
                </div>
                <p className='tituloNotificacao'>{notificacao.titulo}</p>
                <p className='textoNotificacao'>{notificacao.texto}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}

export default NotificacoesPage;
