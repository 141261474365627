import React, { useEffect, useState } from 'react';
import './SelecionarItensNotaFiscal.css';
import { useParams, Link, useNavigate } from 'react-router-dom';
import ItensNotaFiscalFluxoGarantia from '../../componentes/ItensNotaFiscalFluxoGarantia';
import BarraDePesquisa from '../../componentes/BarraDePesquisa';
import imagemInterrocacao from './imgs/ImagemInterrogacao.png';
import AlertBox from '../../componentes/AlertBox';
import Cookies from 'js-cookie';
import Header from '../../componentes/Header';
import Paginate from '../../componentes/Paginate';

const SelecionarItensNotaFiscalGarantia = () => {
  const navigate = useNavigate();
  const { numeroDaNota } = useParams();
  const [notaFiscal, setNotaFiscal] = useState(null);
  const [filtroPesquisa, setFiltroPesquisa] = useState('');
  const isGarantia = window.location.pathname.includes('/garantia/');
  const [alertBoxVisivel, setalertBoxVisivel] = useState(false);

  const [todosOsItensFiscais, setTodosOsItensFiscais] = useState([]);

  const [itensSelecionados, setItensSelecionados] = useState(null);
  const [mobile, setMobile] = useState(window.innerWidth <= 850);

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  useEffect(() => {
    if (mobile === true) {
      document.documentElement.classList.add('no-scroll');
    } else {
      document.documentElement.classList.remove('no-scroll');
    }
  }, [mobile]);
  const toggleItemSelecionado = (item) => {
    setItensSelecionados(item);
  };

  const atualizarQuantidadeSelecionada = (novaQuantidade) => {
    if (itensSelecionados) {
      const novoItemSelecionado = { ...itensSelecionados };
      novoItemSelecionado.quantidadeSelecionada = novaQuantidade;
      setItensSelecionados(novoItemSelecionado);
  
      const novosTodosOsItens = todosOsItensFiscais.map((item) =>
        item.codigoDaPeca === novoItemSelecionado.codigoDaPeca ? { ...item, quantidadeSelecionada: novaQuantidade } : item
      );
      setTodosOsItensFiscais(novosTodosOsItens);
    }
  };

  const handleClickProsseguir = () => {
    if (itensSelecionados !== null) {
      const motivo = isGarantia ? 'motivoGarantia' : 'motivoDevolucao';
      navigate(`/AbrirNovoProtocolo/${isGarantia ? 'garantia' : 'devolucao'}/selecionarNotaFiscal/${numeroDaNota}/${motivo}`, { state: { itensSelecionados, notaFiscal } });
    } else {
      setalertBoxVisivel(true);
      setTimeout(() => {
        setalertBoxVisivel(false);
      }, 5000);
    }

  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = new Headers({
            'Authorization': `Bearer ${token}`,
          });

          const response = await fetch(`${process.env.REACT_APP_API_URL}/notas-fiscais/${numeroDaNota}`, { headers });

          if (response.ok) {
            const data = await response.json();
            setNotaFiscal(data);
            setTodosOsItensFiscais(data.itensFiscais)
          } else {
            console.error('Erro na requisição:', response.statusText);
          }
        } else {
          console.error('Token não encontrado no localStorage.');
        }
      } catch (error) {
        console.error('Erro ao obter nota fiscal:', error);
      }
    };

    fetchData();
  }, [numeroDaNota]);
  const [currentPage, setCurrentPage] = useState(1);
  var itemsPerPage = 3;
  if (mobile) {
    itemsPerPage = 2;
  }
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  if (todosOsItensFiscais) {
    var currentItems = todosOsItensFiscais
      .filter(item => item.codigoDaPeca.toLowerCase().includes(filtroPesquisa.toLowerCase()))
      .slice(indexOfFirstItem, indexOfLastItem);
  }
  const paginate = pageNumber => setCurrentPage(pageNumber);

  return (
    <div>
      <Header logado={true} />
      <div>
        <AlertBox visivel={alertBoxVisivel} texto='Atenção! Você deve selecionar os itens e a quantidade de cada para prosseguir com a solicitação de troca.' />
        {!mobile ? (<label className='indicacaoDeCaminho' style={{ marginLeft: '5lh' }}>Lista de compras ❯ Abrir novo protocolo</label>) :
          (
            <div style={{ display: 'flex', gap: '1em', flexDirection: 'column', marginTop: '1em' }}>
              <div style={{ display: 'flex', alignItems: 'center', gap: '0.5em', marginLeft: '5%' }}>
                <div className='indicacaoDeEtapaAtualNumeroMobile'>1</div>
                <label className='labelDoIndicacaoDeEtapaAtualNumeroMobile'>Seleção de item para Garantia</label>
              </div>
              <div className='barraMaiorIndicacaoMobile'>
                <div className='barraMenorIndicacaoMobile' />
              </div>
            </div>
          )}
        <h1 className='titulo tituloMobile'>Selecione o item desejado</h1>
        {mobile ? (<div className='barraDePesquisaMobileSelecionarItensDaNota'><BarraDePesquisa text="Pesquisar pelo código da peça" onPesquisaChange={setFiltroPesquisa} /></div>) : null}
        <div className='meioDaPagina'>
          {notaFiscal && (
            <div style={{ paddingRight: mobile ? '6%' : '0' }}>
              <div className='topo'>
                <h2 style={{ marginBottom: "0" }}>Nota Fiscal - nº {notaFiscal.numeroNotaFiscal}</h2>
                <div style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex" }}>
                    <p style={{ marginRight: "4lh" }}>Pedido n° {notaFiscal.id}</p>
                    <p>Data: {notaFiscal.data}</p>
                  </div>
                  {!mobile ? (<div style={{ display: "flex", marginBottom: "1lh" }}>
                    <BarraDePesquisa text="Pesquisar pelo código da peça" onPesquisaChange={setFiltroPesquisa} />
                    <p style={{ cursor: 'pointer' }} onClick={handleClickProsseguir} className='solicitarTroca'>
                      <img src={imagemInterrocacao} alt='Imagem Interrogação' className='imagemInterrogacao'></img>
                      Solicitar Troca
                    </p>
                  </div>) : null}
                </div>
              </div>
              <div>
                <table className='tabela'>
                  <thead>
                    <tr style={{ color: "#3E3E3E" }}>
                      <th>Sel.</th>
                      <th>Qtd. Sel.</th>
                      <th>Código Peça</th>
                      <th>Qtd.</th>
                      <th>CST</th>
                      <th>CFOP</th>
                      <th>Valor Sub. Trib.</th>
                      <th>IPI</th>
                      <th>Aliq. IPI</th>
                      <th>Base ICMS</th>
                      <th>ICMS</th>
                      <th>Aliq. ICMS</th>
                      <th>FCP ST</th>
                      <th>Valor Uni.</th>
                      <th>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentItems.map((item) => (
                        <ItensNotaFiscalFluxoGarantia
                          key={item.codigoDaPeca}
                          item={item}
                          selecionar={true}
                          onToggleItemSelecionado={toggleItemSelecionado}
                          onUpdateQuantidadeSelecionada={atualizarQuantidadeSelecionada}
                          itemSelecionado={itensSelecionados}
                        />
                      ))}
                  </tbody>
                </table>
                <Paginate
                  itemsPerPage={itemsPerPage}
                  totalItems={notaFiscal.itensFiscais.length}
                  currentPage={currentPage}
                  paginate={paginate} />
              </div>
            </div>
          )}
          <div className='botoesInferiores'>
            <Link to={"/historico"} style={{ width: 'fit-content', height: 'fit-content' }}>
              <div className='botaoCancelar'>Cancelar</div>
            </Link>
            <div onClick={handleClickProsseguir} style={{ width: 'fit-content', height: 'fit-content', cursor: 'pointer' }}>
              <div className='botaoProsseguir'>
                <label style={{ cursor: 'pointer' }}>Prosseguir</label>
                <label style={{ cursor: 'pointer' }}>❯</label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SelecionarItensNotaFiscalGarantia;
