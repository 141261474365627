import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import "./DetalharProtocolo.css";
import Header from '../../componentes/Header';
import { useParams } from 'react-router-dom';
import Cookies from 'js-cookie';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import LinhaDoTempoProtocolo from '../../componentes/LinhaDoTempoProtocolo';
import icon1BaixarArquivoGiros from './imgs/icon1BaixarArquivoGiros.svg';
import icon2BaixarArquivoGiros from './imgs/icon2BaixarArquivoGiros.svg';
import LinhaDoTempoProtocoloMobile from '../../componentes/LinhaDoTempoProtocoloMobile';
const formatarValor = (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
const DetalharProtocolo = () => {
  const [mobile, setMobile] = useState(window.innerWidth <= 850);

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const [protocoloDetalhado, setProtocoloDetalhado] = useState([]);
  const [tipoProtocolo, setTipoProtocolo] = useState('');
  const [itensDaNota, setItensDaNota] = useState([]);
  const { idDoProtocolo } = useParams();
  const [valorTotalFormatado, setValorTotalFormatado] = useState('');
  const [arquivosGirosDoGet, setArquivosGirosDoGet] = useState([]);
  const [comprovantesCliente, setComprovantesCliente] = useState([]);
  const BaixarArquivoGiros = async (nomeArquivo) => {
    try {
      const token = Cookies.get('token');
      if (token) {
        const headers = { 'Authorization': `Bearer ${token}` };
        const response = await fetch(`${process.env.REACT_APP_API_URL}/protocolo/baixarArquivoPorNome/${nomeArquivo}`, { headers });

        if (!response.ok) {
          throw new Error(`Erro na resposta do servidor: ${response.status} - ${response.statusText}`);
        }

        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));

        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', nomeArquivo);

        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      console.error('Erro ao baixar o arquivo', error);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = new Headers({
            'Authorization': `Bearer ${token}`,
          });

          const response = await fetch(`${process.env.REACT_APP_API_URL}/protocolo/${idDoProtocolo}`, { headers });

          if (response.ok) {
            const data = await response.json();
            setProtocoloDetalhado(data);
          } else {
            console.error('Erro na requisição:', response.statusText);
          }
        } else {
          console.error('Token não encontrado no localStorage.');
        }
      } catch (error) {
        console.error('Erro ao obter notas fiscais:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    if (protocoloDetalhado.listaDeItens) {
      setItensDaNota(protocoloDetalhado.listaDeItens);
      setValorTotalFormatado(formatarValor(protocoloDetalhado.valorTotal));
    }
    if (protocoloDetalhado.tipo === "DEVOLUCAO") {
      setTipoProtocolo("Devolução")
    } else if (protocoloDetalhado.tipo === "GARANTIA") {
      setTipoProtocolo("Garantia")
    } else if (protocoloDetalhado.tipo === "GARANTIA_EXPRESSA") {
      setTipoProtocolo("Garantia Expressa")
    } else {
      console.error("Ocorreu um erro ao receber o tipo do protocolo")
    }
    if (protocoloDetalhado.arquivosDaGiros) {
      setArquivosGirosDoGet(protocoloDetalhado.arquivosDaGiros);
    }
    if (protocoloDetalhado.comprovantes) {
      setComprovantesCliente(protocoloDetalhado.comprovantes);
    }
    if (protocoloDetalhado.listaDeItens) {
      const novosItens = protocoloDetalhado.listaDeItens.map(item => {
        if (item.quantidadeSelecionada === undefined || item.quantidadeSelecionada === null) {
          item.quantidadeSelecionada = item.quantidade;
        }
        return item;
      });

      setItensDaNota(novosItens);
    }
  }, [protocoloDetalhado]);

  const formatarValoresDaTabela = (rowData, columnProps) => {
    const valor = rowData[columnProps.field];
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor);
  };

  return (
    <div>
      <Header logado={true} />
      <Link to='/ListaDeProtocolos'>
        <h1 className='botaoDeVoltar botaoVoltarDetalharProtocoloMobile' style={{ marginLeft: '2.5lh' }}> ❮  Voltar</h1>
      </Link>
      <div className={mobile ? "conteudoDaPaginaDetalharProtocolo" : "conteudoDaPagina"}>
        <h1 className='tituloMaiorDetalharProtocoloMobile' style={{ textAlign: 'center', color: '#041E42' }}>Protocolo de {tipoProtocolo} - n° {protocoloDetalhado.id}</h1>
        {protocoloDetalhado.status !== null ? (
          !mobile ? (
            <div style={{ marginTop: '2lh' }}><LinhaDoTempoProtocolo status={protocoloDetalhado.status}></LinhaDoTempoProtocolo></div>
          )
            : (
              <div style={{ marginTop: '2lh' }}><LinhaDoTempoProtocoloMobile status={protocoloDetalhado.status}></LinhaDoTempoProtocoloMobile></div>
            )
        ) : null}
        {!mobile ? (
          <div className='grade1' style={{ marginTop: '5lh' }}>
            <div className='superior'>CNPJ</div>
            <div className='superior' style={{ marginLeft: '-1lh', marginRight: '-1lh' }}>Usuário</div>
            <div className='superior'>Nota de devolução</div>

            <div className='inferior'>
              {protocoloDetalhado.cnjpj}
            </div>
            <div className='inferior' style={{ marginLeft: '-1lh', marginRight: '-1lh' }}>
              {protocoloDetalhado.nome}
            </div>
            <div className='inferior'>
              Indefinido
            </div>

            <div className='superior'>Meio de recebimento</div>
            <div className='superior' style={{ marginLeft: '-1lh', marginRight: '-1lh' }}>Responsável pelo custo do frete</div>
            <div className='superior'>Total</div>

            <div className='inferior'>{protocoloDetalhado.meioRecebimento ? (protocoloDetalhado.meioRecebimento) : ("Ainda não informado")}</div>
            <div className='inferior' style={{ marginLeft: '-1lh', marginRight: '-1lh' }}>{protocoloDetalhado.responsavelFrete ? (protocoloDetalhado.responsavelFrete) : ("Ainda não informado")}</div>
            <div className='inferior'>{(valorTotalFormatado)}</div>

            <div className='superior'>Motivo de devolução</div>
            <div className='superior' style={{ marginLeft: '-1lh', marginRight: '-1lh' }}></div>
            <div className='superior'></div>

            <div style={{ borderBottom: 'none' }} className='inferior'>{String(protocoloDetalhado.motivos).replaceAll('_', " ")}</div>
          </div>
        ) : (
          <div className='grade1' style={{ marginTop: '5lh' }}>

            <div className='superior' style={{ paddingTop: '0.5em' }}>CNPJ</div>
            <div className='inferior'>{protocoloDetalhado.cnjpj}</div>

            <div className='superior'>Meio de Recebimento</div>
            <div className='inferior'>{protocoloDetalhado.meioRecebimento ? (protocoloDetalhado.meioRecebimento) : ("Ainda não informado")}</div>

            <div className='superior'>Usuário</div>
            <div className='inferior'>{protocoloDetalhado.nome}</div>

            <div className='superior'>Responsável pelo custo do frete</div>
            <div className='inferior'>{protocoloDetalhado.responsavelFrete ? (protocoloDetalhado.responsavelFrete) : ("Ainda não informado")}</div>

            <div className='superior'>Nota de devolução</div>
            <div className='inferior'>Indefinido</div>

            <div className='superior'>Total</div>
            <div className='inferior' style={{ borderBottom: 'none' }}>{valorTotalFormatado}</div>
          </div>
        )}
        <h2 style={{ color: '#003594', fontWeight: '700' }}>Endereço para envio</h2>
        {!mobile ? (
          <div className='grade2'>
            <div className='superior'>Nome da empresa</div>
            <div className='superior' style={{ marginLeft: '-1lh', marginRight: '-1lh' }}>Logradouro</div>
            <div className='superior'>Número</div>

            <div className='inferior'>{protocoloDetalhado.nomeDaEmpresa}</div>
            <div className='inferior' style={{ marginLeft: '-1lh', marginRight: '-1lh' }}>{protocoloDetalhado.logradouro}</div>
            <div className='inferior'>{protocoloDetalhado.numero}</div>

            <div className='superior'>Cidade</div>
            <div className='superior' style={{ marginLeft: '-1lh', marginRight: '-1lh' }}>Estado</div>
            <div className='superior'>Cep</div>

            <div className='inferior' style={{ borderBottom: 'none' }}>{protocoloDetalhado.cidade}</div>
            <div className='inferior' style={{ marginLeft: '-1lh', marginRight: '-1lh', borderBottom: 'none' }}>{protocoloDetalhado.estado}</div>
            <div className='inferior' style={{ borderBottom: 'none' }}>{protocoloDetalhado.cep}</div>
          </div>) : (
          <div className='grade1'>
            <div className='superior' style={{ paddingTop: '0.5em' }}>Nome da empresa</div>
            <div className='inferior'>{protocoloDetalhado.nomeDaEmpresa}</div>

            <div className='superior'>Cidade</div>
            <div className='inferior'>{protocoloDetalhado.cidade}</div>

            <div className='superior'>Logradouro</div>
            <div className='inferior'>{protocoloDetalhado.logradouro}</div>

            <div className='superior'>Estado</div>
            <div className='inferior'>{protocoloDetalhado.estado}</div>

            <div className='superior'>Número</div>
            <div className='inferior'>{protocoloDetalhado.numero}</div>

            <div className='superior'>Cep</div>
            <div className='inferior' style={{ borderBottom: 'none' }}>{protocoloDetalhado.cep}</div>

          </div>
        )}
        <h2 style={{ color: '#003594', fontWeight: '700', height: 'fit-content', marginBottom: '0' }}>Itens para {tipoProtocolo}</h2>
        <h4 style={{ fontWeight: '400', marginTop: '0.5lh', height: 'fit-content' }}>Sua nota de {tipoProtocolo} deve conter os seguintes itens:</h4>
        <div className='div3 divDaTabelaDetalharProtocoloMobile'>
          <DataTable value={itensDaNota} className='classeDaDataTable'>
            <Column field="numeroDaNota" header="N° da nota" style={{ textAlign: 'left' }}></Column>
            <Column field="quantidadeSelecionada" header="Qtd" style={{ textAlign: 'left' }}></Column>
            <Column field="codigoDaPeca" header="Código da Peça" style={{ textAlign: 'left' }}></Column>
            <Column field="cst" header="CST" style={{ textAlign: 'left' }}></Column>
            <Column field="cfop" header="CFOP" style={{ textAlign: 'left' }}></Column>
            <Column field="valorSubTrib" body={formatarValoresDaTabela} header="Valor Sub. Trib." style={{ textAlign: 'left' }}></Column>
            <Column field="ipi" body={formatarValoresDaTabela} header="IPI" style={{ textAlign: 'left' }}></Column>
            <Column field="aliqIpi" header="Aliq. IPI" style={{ textAlign: 'left' }}></Column>
            <Column field="baseIcms" body={formatarValoresDaTabela} header="Base ICMS" style={{ textAlign: 'left' }}></Column>
            <Column field="icms" body={formatarValoresDaTabela} header="ICMS" style={{ textAlign: 'left' }}></Column>
            <Column field="fcpSt" body={formatarValoresDaTabela} header="FCP ST" style={{ textAlign: 'left' }}></Column>
            <Column field="valorUnidade" body={formatarValoresDaTabela} header="Valor Uni." style={{ textAlign: 'left' }}></Column>
            <Column field="valorTotal" body={formatarValoresDaTabela} header="Total" style={{ textAlign: 'left' }}></Column>
          </DataTable>
        </div>
        <div>
          <h2 style={{ color: '#003594', fontWeight: '700', height: 'fit-content', marginBottom: '0' }}>Laudos Improcedentes</h2>
          {arquivosGirosDoGet !== null && arquivosGirosDoGet.length > 0 ? (<h4 style={{ fontWeight: '400', marginTop: '0.5lh', height: 'fit-content' }}>Relatórios de Assistência Técnica</h4>) : <h4 style={{ fontWeight: '400', marginTop: '0.5lh', height: 'fit-content' }}>Não possui Laudos Anexados até o momento</h4>}
          <div>
            {arquivosGirosDoGet !== null && arquivosGirosDoGet.length > 0 ? (
              arquivosGirosDoGet.map((file, index) => {
                const match = file.string.match(/^\d+_(.*)$/);
                const nomeSemNumeros = match ? match[1] : file.string;
                return (
                  <div key={index} className='arquivoDaGiros'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
                      <img src={icon1BaixarArquivoGiros} alt=''></img>
                      {nomeSemNumeros}
                    </div>
                    <span onClick={() => BaixarArquivoGiros(file.string)} style={{ display: 'flex', alignItems: 'center', gap: '1em', cursor: 'pointer' }}>
                      <img src={icon2BaixarArquivoGiros} alt=''></img>
                      Baixar
                    </span>
                  </div>
                );
              })
            ): null}
          </div>
        </div>
        <div>
          <h2 style={{ color: '#003594', fontWeight: '700', height: 'fit-content', marginBottom: '0', marginTop: '3em' }}>Comprovantes para Laudo Técnico</h2>
          {comprovantesCliente !== null && comprovantesCliente.length > 0 ? (<h4 style={{ fontWeight: '400', marginTop: '0.5lh', height: 'fit-content' }}>Comprovantes de protocolo de {tipoProtocolo}</h4>) : <h4 style={{ fontWeight: '400', marginTop: '0.5lh', height: 'fit-content' }}>Não possui comprovantes anexados</h4>}
          <div>
          {comprovantesCliente !== null && comprovantesCliente.length > 0 ? (
              comprovantesCliente.map((file, index) => {
                const match = file.string.match(/^\d+_(.*)$/);
                const nomeSemNumeros = match ? match[1] : file.string;
                return (
                  <div key={index} className='arquivoDaGiros'>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '1em' }}>
                      <img src={icon1BaixarArquivoGiros} alt=''></img>
                      {nomeSemNumeros}
                    </div>
                    <span onClick={() => BaixarArquivoGiros(file.string)} style={{ display: 'flex', alignItems: 'center', gap: '1em', cursor: 'pointer' }}>
                      <img src={icon2BaixarArquivoGiros} alt=''></img>
                      Baixar
                    </span>
                  </div>
                );
              })
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetalharProtocolo;
