import React from 'react';
import { Link } from 'react-router-dom';
import iconVoltar from './iconVoltar.svg';
import './BotaoDeVoltarAdmin.css';
const BotaoDeVoltarAdmin = () => {
    return (
        <Link to={'/admin'} className='botaoVoltarAdmin'>
            <img src={iconVoltar} alt=''></img>
            <label>Voltar</label>
        </Link>
    );
}

export default BotaoDeVoltarAdmin;