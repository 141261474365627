import Header from '../../../componentes/Header';
import React, { useState } from 'react';
import './TrabalheConosco.css';
import { useEffect } from 'react';
import Cookies from 'js-cookie';
import iconeUnidades from './imgs/iconeUnidadesPaginaContato.svg';
import bannerGptw from './imgs/bannerGptwTrabalheConosco.jpg';
import certificadoGptw from './imgs/certificadoGptw.png';
import anexarCurriculoIcon from './imgs/anexarCurriculoIcon.svg';
import icon1TrabalheConosco from './imgs/icon1TrabalheConosco.svg';
import icon2TrabalheConosco from './imgs/icon2TrabalheConosco.svg';
import BottomSitePublico from '../../../componentes/BottomSitePublico';
import { Link } from 'react-router-dom';
import BannerGptwTrabalheConosco from '../../../componentes/BannerGptwTrabalheConosco';

function TrabalheConosco() {
  const [mobile, setMobile] = useState(window.innerWidth <= 850);
  const [formulario, setFormulario] = useState({
    nome: "",
    telefone: "",
    email: "",
    curriculo: null
  });

  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });

  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setStatusUsuario({ logado: true, admin: false });
      const fetchData = async () => {
        try {
          if (token) {
            const headers = new Headers({
              'Authorization': `Bearer ${token}`,
            });
            const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, { headers });
            const data = await response.json();
            setStatusUsuario({ logado: true, admin: data });
          }
        } catch (error) {
          setStatusUsuario({ logado: false, admin: false });
        }
      };

      fetchData();
    } else {
      setStatusUsuario({ logado: false, admin: false });
    }
  }, []);

  const handleFormularioChange = (campo, valor) => {
    if (campo === "telefone") {
      setFormulario({ ...formulario, telefone: formatarTelefone(valor) });
    } else {
      setFormulario({ ...formulario, [campo]: valor });
    }
  };

  const formatarTelefone = (valor) => {
    valor = valor.replace(/\D/g, "");
    valor = valor.replace(/^(\d{2})(\d)/g, "($1) $2");
    valor = valor.replace(/(\d)(\d{4})$/, "$1-$2");
    return valor;
  };

  const handleFileChange = (e) => {
    const arquivo = e.target.files[0];
    setFormulario({ ...formulario, curriculo: arquivo });
  };

  const triggerFileInput = () => {
    document.getElementById('inputCurriculo').click();
  };

  const enviarFormulario = async () => {
    const token = Cookies.get('token');
    if (!token) {
      console.log("Você precisa estar logado em uma conta para enviar um formulário!");
      return;
    }

    if (!formulario.nome || !formulario.telefone || !formulario.email || !formulario.curriculo) {
      console.log("Preencha todos os campos do formulário");
      return;
    }

    try {
      const formData = new FormData();
      formData.append('nome', formulario.nome);
      formData.append('telefone', formulario.telefone);
      formData.append('email', formulario.email);
      formData.append('curriculo', formulario.curriculo);

      const headers = new Headers({
        'Authorization': `Bearer ${token}`,
      });

      const response = await fetch(`${process.env.REACT_APP_API_URL}/email/enviarFormTrabalheConosco`, {
        method: 'POST',
        headers,
        body: formData,
      });

      if (response.ok) {
        console.log("Mensagem enviada com sucesso");
        setFormulario({ nome: "", telefone: "", email: "", curriculo: null });
      } else {
        console.log("Erro ao enviar mensagem");
      }
    } catch (error) {
      console.log(error);
      console.log("Erro ao enviar mensagem");
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="trabalheConoscoDivMaior">
      <Header logado={statusUsuario.logado ? true : false} admin={statusUsuario.admin ? true : false} />
      <div className='divMaiorMobileSitePublico'>
        <BannerGptwTrabalheConosco></BannerGptwTrabalheConosco>
        <div className='divFundoBrancoPaginaContato' style={{ margin: '0', padding: '2% 10%' }}>
          <div className='divMaiorTituloContato' style={{ display: 'flex', justifyContent: 'space-between', gap: '10%' }}>
            <div className='divDoTituloESubtituloPaginaContato'>
              <h1 className='tituloPaginaContato'>Trabalhe conosco e faça parte do time Giros</h1>
              <p className='textoTituloPaginaContato'>Estamos aqui para ajudar. Preencha o formulário, ligue ou envie um e-mail para entrar em contato. Esperamos poder atendê-lo em suas necessidades.</p>
              <div style={{ display: 'flex', flexDirection: 'column', gap: '1em', marginTop: '3em' }}>
                <label style={{ display: 'flex', alignItems: 'center', gap: '1em' }}><img alt='icone de telefone' src={icon1TrabalheConosco}></img>Prezamos por um bom ambiente de trabalho</label>
                <label style={{ display: 'flex', alignItems: 'center', gap: '1em' }}><img alt='Icone de email' src={icon2TrabalheConosco}></img>Trabalhamos com as melhores marcas</label>
              </div>
            </div>
            <div style={{ width: !mobile ? 'auto' : '100%' }}>
              <h3 className='tituloCadastroTrabalheConosco'>Cadastro</h3>
              <h4 className='subtituloCadastroTrabalheConosco'>Preencha os dados abaixo, envie seu currículo e faça parte da nossa história.</h4>
              <div className='divDosInputsPaginaCOntato' style={{ width: 'auto' }}>
                <div className='divInputPagContato'>
                  <label>Nome</label>
                  <input value={formulario.nome} onChange={(e) => handleFormularioChange("nome", e.target.value)} type='text' />
                </div>
                <div className='divInputPagContato'>
                  <label>Telefone</label>
                  <input pattern="\(\d{2}\) \d{4,5}-\d{4}" maxLength="15" value={formulario.telefone} onChange={(e) => handleFormularioChange("telefone", e.target.value)} type='tel' />
                </div>
                <div className='divInputPagContato'>
                  <label>E-mail</label>
                  <input value={formulario.email} onChange={(e) => handleFormularioChange("email", e.target.value)} type='email' />
                </div>
                <div className='divInputPagContato'>
                  <label>Currículo</label>
                  <input id='inputCurriculo' type='file' style={{ display: 'none' }} onChange={handleFileChange} />
                  <button type='button' className='botaoEnviarCurriculo' onClick={triggerFileInput}>
                    <img src={anexarCurriculoIcon} alt="Ícone de anexar currículo" />
                    Anexar Currículo
                  </button>
                  {formulario.curriculo && <span>{formulario.curriculo.name}</span>}
                </div>
                <button className='botaoEnviarMensagemPagContato' onClick={enviarFormulario}>Enviar mensagem</button>
              </div>
            </div>
          </div>
        </div>
        <h2 className='tituloNossasUnidadesPaginaTrabalheConosco' style={{ textAlign: 'center' }}>Nossas Unidades</h2>
        <div className='divUnidadesPaginaTrabalheConosco'>
          <div className='divDaUnidadePaginaTrabalheConosco'>
            <Link title='Veja como chegar' to={'https://www.google.com/maps/dir//Av.+Cairú,+1045+-+Navegantes,+Porto+Alegre+-+RS,+90230-030/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x951979eb8eaf7999:0x5de1dd18d137e62c?sa=X&ved=1t:707&ictx=111'} target="_blank"><label style={{ cursor: 'pointer', fontWeight: '700', gap: '0.5em', display: 'flex', alignItems: 'center' }}><img alt='icone simbolizando localização' src={iconeUnidades}></img>Unidade Giros - Porto Alegre</label></Link>
            <label>Av. Cairu, 1045 - Porto Alegre/RS </label>
            <label>(51) 3396-0300</label>
          </div>
          <div className='divDaUnidadePaginaTrabalheConosco'>
            <Link title='Veja como chegar' to={'https://www.google.com/maps/dir//R.+Carlos+Essenfelder,+2759+-+Boqueirão,+Curitiba+-+PR,+81730-060/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x94dcfaee858725e7:0x47ab45af1fa9127a?sa=X&ved=1t:707&ictx=111&cshid=1726014740236059'} target="_blank"><label style={{ cursor: 'pointer', fontWeight: '700', gap: '0.5em', display: 'flex', alignItems: 'center' }}><img alt='icone simbolizando localização' src={iconeUnidades}></img>Unidade Giros - Curitiba</label></Link>
            <label>Rua Carlos Essenfelder, 2759 - Curitiba/PR</label>
            <label>(41) 3532-2900</label>
          </div>
          <div className='divDaUnidadePaginaTrabalheConosco'>
            <Link title='Veja como chegar' to={'https://www.google.com/maps/dir//Av.+Ivo+Luchi,+207+-+Jardim+Eldorado,+Palhoça+-+SC,+88133-510/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x952735937c0a2785:0x7177c3b853f1bb8c?sa=X&ved=1t:707&ictx=111'} target="_blank"><label style={{ cursor: 'pointer', fontWeight: '700', gap: '0.5em', display: 'flex', alignItems: 'center' }}><img alt='icone simbolizando localização' src={iconeUnidades}></img>Unidade Giros - Palhoça</label></Link>
            <label>Av. Ivo Lucchi, 207 - Palhoça/SC</label>
            <label>(48) 3380-3700</label>
          </div>
          <div className='divDaUnidadePaginaTrabalheConosco'>
            <Link title='Veja como chegar' to={'https://www.google.com/maps/dir//R.+Equador,+275d+-+Líder,+Chapecó+-+SC,+89805-210/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x94e4b5d651df00c9:0x59d7a44b7a058bea?sa=X&ved=1t:707&ictx=111'} target="_blank"><label style={{ cursor: 'pointer', fontWeight: '700', gap: '0.5em', display: 'flex', alignItems: 'center' }}><img alt='icone simbolizando localização' src={iconeUnidades}></img>Unidade Giros - Chapecó</label></Link>
            <label>Rua Equador 275 D - Chapecó/SC</label>
            <label>(48) 3380-3700</label>
          </div>
        </div>
        <BottomSitePublico></BottomSitePublico>
      </div>
    </div >
  );
}

export default TrabalheConosco;
