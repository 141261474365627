import React, { useEffect, useState } from 'react';
import './ListagemDeChats.css';
import XFecharChat from './img/XFecharChat.svg';
import Chat from '../Chat';
import Cookies from 'js-cookie';

const ListagemDeChats = ({ onClose }) => {
  const [chats, setChats] = useState([]);
  const [idProtocolo, setIdProtocolo] = useState(null);
  const [titulo, setTitulo] = useState(null);
  const [chatVisible, setChatVisible] = useState(false);

  useEffect(() => {
    const carregarMensagens = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = { 'Authorization': `Bearer ${token}` };
          const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/listar-mensagens-usuario`, { headers });

          if (!response.ok) {
            throw new Error(`Erro na resposta do servidor: ${response.status} - ${response.statusText}`);
          }

          const data = await response.json();
          setChats(data);
        }
      } catch (error) {
        console.error('Erro ao detalhar o protocolo de garantia', error);
      }
    };

    carregarMensagens();
  }, []);

  const AbrirChat = (id, titulo) => {
    setIdProtocolo(id);
    setTitulo(titulo);
    setChatVisible(true);
    document.body.classList.add('no-overflow');
  };

  const FecharChat = () => {
    setChatVisible(false);
    document.body.classList.remove('no-overflow');
  };
  const corrigirMensagemDeArquivo = (msg) => {
    const texto = msg.replaceAll("||Arquivo||", "");
    const match = texto.match(/^\d+_(.*)$/);
    const nomeSemNumeros = match ? match[1] : texto;
    return nomeSemNumeros;
  }
  return (
    <div className={"divDoChat"} style={{ top: '180px' }}>
      <div className="chatHeader">
        <button className='botaoFecharChat' onClick={onClose}><img src={XFecharChat} alt=''></img></button>
        <h1 className='tituloListaDeMensagens'>LISTA DE MENSAGENS</h1>
      </div>
      <div className="listaDeChats">
        {chats ? (
          chats.map((chat, index) => (
            <div key={index}>
              <div className={index % 2 === 0 ? "divDeCadaChatTipo1" : "divDeCadaChatTipo2"} style={{ cursor: 'pointer' }} onClick={() => AbrirChat(chat.idProtocolo, `${chat.tipoProtocolo} n° #${chat.idProtocolo}`)}>
                <label>
                  <h3 className='tituloReferenciaChat'>PROTOCOLO DE {(chat.tipoProtocolo).replaceAll("_", " ")} N° #{chat.idProtocolo}</h3>
                </label>
                <div className="textoDoChatDaListagem">
                  <label className='breveTextoDoChat'>
                    {chat.texto !== null && chat.texto !== undefined ? (
                      chat.texto.length > 40 ? (
                        chat.texto.includes("||Arquivo||") ? (
                          `Arquivo Enviado: ${corrigirMensagemDeArquivo(chat.texto).substring(0, 40)}...`
                        ) : (
                          `${chat.texto.substring(0, 40)}...`
                        )
                      ) :
                      (
                          chat.texto.includes("||Arquivo||") ? (
                            "Arquivo Enviado: " + corrigirMensagemDeArquivo(chat.texto)
                          ) : (
                            chat.texto
                          )
                      )
                    ) : null}
                  </label>
                  <label className='dataDoChat'>
                    {`${new Date(chat.data).toLocaleDateString('pt-BR')}, ${new Date(chat.data).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}`}
                  </label>
                </div>
              </div>
            </div>
          ))
        ) : null}
      </div>
      {chatVisible && <Chat idProtocolo={idProtocolo} onClose={FecharChat} titulo={titulo} />}
    </div>
  );
};

export default ListagemDeChats;