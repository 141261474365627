import React from 'react';
import backgroundImageBannerGptw from './imgs/backgroundImageBannerGptw.jpg'
import certificadoGptw from './imgs/certificadoGptw.png';
import logoGiros from './imgs/LogoGirosBannerGptw.svg';
import './BannerGptw.css';
import { Link } from 'react-router-dom';
const BannerGptw = () => {
  return (
    <div id="novo_GPTW" className="gptw-container">
      <img
        className="gptw-background-img"
        src={backgroundImageBannerGptw}
        alt="Background"
      />
      <div className="gptw-overlay"></div>
      <img
        src={certificadoGptw}
        alt="GPTW logo"
        className="gptw-logo"
      />
      <div className="gptw-content">
        <h1>Nós somos GPTW</h1>
        <p>
          GPTW é certificado muito importante para atestar a qualidade da
          empresa na gestão de pessoas e na cultura organizacional!
        </p>
        <div className='gptw-buttons'>
          <Link to={'/trabalheConosco'}>
            <button className="gptw-btn">Trabalhe Conosco</button>
          </Link>
          <img src={logoGiros} alt='Logo da giros pecas'></img>
        </div>
      </div>
    </div>
  );
}
export default BannerGptw;