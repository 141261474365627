import React, { useEffect, useState } from 'react';
import './Chat.css';
import XFecharChat from './img/XFecharChat.svg';
import EnviarMensagemIcon from './img/EnviarMensagemIcon.svg';
import imagemTipoMensagemArquivo from './img/imagemTipoMensagemArquivo.svg';
import Cookies from 'js-cookie';

const Chat = ({ onClose, idProtocolo, titulo }) => {
  const [messages, setMessages] = useState([]);
  const [nomeUsuario, setNomeUsuario] = useState("");
  const [newMessage, setNewMessage] = useState('');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = new Headers({
            'Authorization': `Bearer ${token}`,
          });

          const response = await fetch(`${process.env.REACT_APP_API_URL}/protocolo/resgatarNomeUsuario`, { headers });
          const data = await response.json();
          setNomeUsuario(data.nome);
        }
      } catch (error) {
        console.error('Erro ao obter nome de usuário:', error);
      }
    };

    fetchData();
  }, []);
  const handleSendMessage = () => {
    setMessages([...messages, { texto: newMessage, nomeRemetente: nomeUsuario, dataHora: new Date().toLocaleString() }]);
    const enviarMensagem = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`
          };
          const requestData = {
            nomeRemetente: null,
            texto: newMessage,
            idProtocolo: idProtocolo,
            dataHora: null,
          };
          const response = await fetch(`${process.env.REACT_APP_API_URL}/chat/enviar-mensagem`, { method: 'POST', headers: headers, body: JSON.stringify(requestData) });
          if (!response.ok) {
            throw new Error(`Erro na resposta do servidor: ${response.status} - ${response.statusText}`);
          }

          const data = await response.json();
          setMessages(data);
        }
      } catch (error) {
        console.error('Erro ao detalhar o protocolo de garantia', error);
      }
    };

    enviarMensagem();
    setNewMessage('');
  };
  useEffect(() => {
    const carregarMensagens = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = { 'Authorization': `Bearer ${token}` };
          const response = await fetch(`http://localhost:8029/chat/carregar-mensagens/${idProtocolo}`, { headers });

          if (!response.ok) {
            throw new Error(`Erro na resposta do servidor: ${response.status} - ${response.statusText}`);
          }

          const data = await response.json();
          setMessages(data);
        }
      } catch (error) {
        console.error('Erro ao detalhar o protocolo de garantia', error);
      }
    };

    carregarMensagens();
  }, [idProtocolo]);
  return (
    <div className={"divDoChat"} style={{ top: '180px' }}>
      <div className="chatHeader">
        <button className='botaoFecharChat' onClick={onClose}><img src={XFecharChat} alt=''></img></button>
        <h1 className='tituloListaDeMensagens'>{titulo.replaceAll("_", " ")}</h1>
      </div>
      <div className="mensagensDoChat">
        {messages && messages.length > 0 ? (
          messages.map((message, index) => (
            <div key={index} className={message.nomeRemetente === nomeUsuario ? "mensagemDireita" : "mensagemEsquerda" + (message.texto.includes("||Arquivo||") ? " mensagemTipoArquivo" : "")}>
              <div className="divDaMensagem">
                <label>
                  <h3 className='nomeMensagem'>{message.nomeRemetente === nomeUsuario ? "Você" : message.nomeRemetente}</h3>
                  <h3 className='dataMensagem'>
                    {`${new Date(message.dataHora).toLocaleDateString('pt-BR')}, ${new Date(message.dataHora).toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' })}`}
                  </h3>
                </label>
                <div className="textoDaMensagem">
                  {message.texto.includes("||Arquivo||") ? (
                    (() => {
                      const texto = message.texto.replaceAll("||Arquivo||", "");
                      const match = texto.match(/^\d+_(.*)$/);
                      const nomeSemNumeros = match ? match[1] : texto;
                      return <label><img src={imagemTipoMensagemArquivo} alt=''></img>{nomeSemNumeros}</label>;
                    })()
                  ) : (
                    message.texto
                  )}
                </div>
              </div>
            </div>
          ))
        ) : (
          <div>Não há mensagens</div>
        )}
      </div>
      <div className="chatBottom">
        <input
          type="text"
          placeholder="Escreva sua mensagem..."
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />
        <button onClick={handleSendMessage}><img src={EnviarMensagemIcon} alt=''></img></button>
      </div>
    </div>
  );
};

export default Chat;
