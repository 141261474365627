import React, { useEffect, useState } from 'react';
import './ItensNotaFiscal.css';
import setaParaCima from '../SelecionarItensNotaFiscal/imgs/ImagemSetaParaCima.png';
import setaParaBaixo from '../SelecionarItensNotaFiscal/imgs/ImagemSetaParaBaixo.png';

const formatarValor = (valor) => valor.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
const ItensNotaFiscalFluxoGarantia = ({ item, selecionar, quantidadeSelecionada, espacamento, onToggleItemSelecionado, onUpdateQuantidadeSelecionada, itemSelecionado }) => {
  const [isChecked, setChecked] = useState(false);
  const [quantidade, setQuantidade] = useState(item.quantidade);
  const [alterado, setAlterado] = useState(false);

  useEffect(() => {
    if(item.quantidadeSelecionada !== undefined && item.quantidadeSelecionada !== null) {
      setQuantidade(item.quantidadeSelecionada);
    }
  }, [item]);

  let paddingValorTotal = { paddingRight: "25vh" };
  let displayDoSelecionar = { display: "none" };

  if (selecionar) {
    paddingValorTotal = { paddingRight: '1vh' };
    displayDoSelecionar = { display: "" };
  }
  if (!espacamento) {
      paddingValorTotal = { paddingRight: '1vh' };
  }
  useEffect(() => {
    if(itemSelecionado != null){
      if(item.codigoDaPeca === itemSelecionado.codigoDaPeca){
        setChecked(true);
      }else{
        setChecked(false);
      }
    }
  }, [itemSelecionado]);

    const incrementarQuantidade = () => {
    if (isChecked && quantidade < item.quantidade) {
      const novaQuantidade = quantidade + 1;
      setQuantidade(novaQuantidade);
      setAlterado(true);
      onUpdateQuantidadeSelecionada(novaQuantidade);
    }
  };
  

  const decrementarQuantidade = () => {
    if (isChecked && quantidade > 1) {
      const novaQuantidade = quantidade - 1;
      setQuantidade(novaQuantidade);
      setAlterado(true);
      onUpdateQuantidadeSelecionada(novaQuantidade);
    }
  };

  const handleClickCheckbox = () => {
    onToggleItemSelecionado(item);
  };  
  const valorSubTribFormatado = formatarValor(item.valorSubTrib);
  const ipiFormatado = formatarValor(item.ipi);
  const baseIcmsFormatado = formatarValor(item.baseIcms);
  const icmsFormatado = formatarValor(item.icms);
  const fcpStFormatado = formatarValor(item.fcpSt);
  const valorTotalFormatado = formatarValor(item.valorTotal);
  const valorUnidadeFormatado = formatarValor(item.valorUnidade);

  return (
    <tr className={isChecked ? 'itemSelecionadoNoFluxo' : ''} style={{ color: "#041E42" }}>
      <td style={{ ...displayDoSelecionar}}>
        <label className='chk'>
          <input
            type='checkbox'
            style={{ display: 'none' }}
            defaultChecked={false}
            checked={isChecked}
            onChange={handleClickCheckbox}
          />
          {isChecked ? <span style={{borderRadius: '50px'}}><span></span></span > : <span style={{borderRadius: '50px'}}></span>}
        </label>
      </td>
      <td style={{ ...displayDoSelecionar }}>
        <div className="quantidadeSeletor">
          <input style={{border: 'none'}} type="number" value={quantidade}/>
          <div>
            <div>
              <button onClick={incrementarQuantidade} style={{background: "none", border: "none", cursor: 'pointer'}}>
                <img src={setaParaCima} style={{width: '12px', height: '8px'}} alt=''></img>
              </button>
            </div>
            <div>
              <button onClick={decrementarQuantidade} style={{background: "none", border: "none", cursor: 'pointer'}}>
                <img src={setaParaBaixo} style={{width: '12px', height: '8px'}} alt=''></img>
              </button>
          </div>
          </div>
      </div>
      </td>
      <td>{item.codigoDaPeca}</td>
      <td>{quantidadeSelecionada != null ? quantidadeSelecionada : item.quantidade}</td>
      <td>{item.cst !== undefined ? item.cst : 'N/A'}</td>
      <td>{item.cfop}</td>
      <td>{item.valorSubTrib !== undefined ? valorSubTribFormatado : 'N/A'}</td>
      <td>{ipiFormatado}</td>
      <td>{item.aliqIpi}</td>
      <td>{baseIcmsFormatado}</td>
      <td>{icmsFormatado}</td>
      <td>{item.aliqIcms}</td>
      <td>{fcpStFormatado}</td>
      <td>{valorUnidadeFormatado}</td>
      <td style={{ ...paddingValorTotal }}>{valorTotalFormatado}</td>
    </tr>
  );
};

export default ItensNotaFiscalFluxoGarantia;
