import Header from '../../../componentes/Header';
import React, { useState, useEffect } from 'react';
import './DetalharProdutoSitePublico.css';
import BottomSitePublico from '../../../componentes/BottomSitePublico';
import Cookies from 'js-cookie';
import { useNavigate, useParams } from 'react-router-dom';
import iconVoltarPagina from './iconVoltarPagina.svg';

function DetalharProdutoSitePublico() {
  const navigate = useNavigate();
  const { idProduto } = useParams();

  // Estado geral
  const [mobile, setMobile] = useState(window.innerWidth <= 850);
  const [statusUsuario, setStatusUsuario] = useState({ logado: false, admin: false });

  // Estado de marcas
  const [produtoDetalhado, setProdutoDetalhado] = useState([]);

  // Efeito para detectar mudança no tamanho da tela
  useEffect(() => {
    function handleResize() {
      setMobile(window.innerWidth <= 850);
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // Efeito para verificar autenticação
  useEffect(() => {
    const token = Cookies.get('token');
    if (token) {
      setStatusUsuario({ logado: true, admin: false });
      const fetchData = async () => {
        try {
          const headers = new Headers({ 'Authorization': `Bearer ${token}` });
          const response = await fetch(`${process.env.REACT_APP_API_URL}/autenticacao/verificarAdmin`, { headers });
          const data = await response.json();
          setStatusUsuario({ logado: true, admin: data });
        } catch (error) {
          setStatusUsuario({ logado: false, admin: false });
        }
      };
      fetchData();
    } else {
      setStatusUsuario({ logado: false, admin: false });
    }
  }, []);

  // Função para detalhar a marca
  async function detalharProduto() {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/produto/${idProduto}`, { method: 'GET' });
      const data = await response.json();
      setProdutoDetalhado(data);
    } catch (error) {
      console.log(error);
    }
  }

  // Efeito para listar marcas e produtos ao carregar a página
  useEffect(() => {
    detalharProduto();
    window.scrollTo(0, 0);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  // Função para transformar byte em imagem
  function transformarByteEmImagem(itemData) {
    const byteCharacters = atob(itemData);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/png' });
    const url = window.URL.createObjectURL(blob);
    return url;
  }
  return (
    <div className="sitePublicoDivMaior">
      <Header logado={statusUsuario.logado ? true : false} admin={statusUsuario.admin ? true : false} />
      <div className='divMaiorMobileSitePublico'>
        <div className='divFundoAzulCatalogoProdutosDetalharMarca produto' style={{ marginTop: '1em', minHeight: '200px'}}>
          <div>
            <div>
              <div className='botaoDeVoltarDetalharMarca' style={{paddingTop: !mobile ? '1em' : '2em', paddingBottom: !mobile ? 'none' : '1em'}} onClick={() => navigate(-1)}>
                <img src={iconVoltarPagina} alt=''></img>
                Voltar
              </div>
            </div>
            <div>
              <h2 className='catalogoDeProdutosTitulo tituloDetalharMarca' style={{textAlign: 'center'}}>Marca {produtoDetalhado.nomeMarca}</h2>
            </div>
          </div>
        </div>
        <div className='divFundoBrancoDetalharProdutoDoSitePublico'>
          <div className='divDentroDoDivBrancoDetalhar' style={{alignItems: 'center'}}>
            {produtoDetalhado.imagem ? <img style={{width: '80%'}} src={transformarByteEmImagem(produtoDetalhado.imagem)} alt='Imagem do produto'></img> : null}
            {produtoDetalhado.imagemMarca ? <img title='Ir para página da marca' onClick={() => navigate(`/detalharMarcaSitePublico/${produtoDetalhado.idMarca}`)} style={{cursor: 'pointer', width: mobile ? '50%' : '30%', opacity: '50%' }} alt={`Logo da Marca ${produtoDetalhado.nomeMarca}`} src={transformarByteEmImagem(produtoDetalhado.imagemMarca)}></img> : null}
          </div>
          <div className='divDentroDoDivBrancoDetalhar'>
            {produtoDetalhado.nome ? <h2>{produtoDetalhado.nome.toUpperCase()}</h2>:null}
            {produtoDetalhado.descricao ? <p>{produtoDetalhado.descricao}</p>:null}
          </div>
        </div>
        <BottomSitePublico />
      </div >
    </div >
  );
}

export default DetalharProdutoSitePublico;
