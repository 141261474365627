import React from 'react';
import './ItemLinhaDoTempoInstitucional.css';
const ItemLinhaDoTempoInstitucional = ({ ano, descricao, imagem, mobile, semLinha }) => (
  !mobile ? (
    <div className={'itemLinhaDoTempoInstitucional'} style={{marginRight: !imagem ? "41%" : "auto"}}>
      <div className='divTextosLinhaDoTempo' style={{width: !imagem ? "90%" : "50%"}}>
        <h2>{ano}</h2>
        <p>{descricao}</p>
      </div>
      <div className={!semLinha ? 'linhaDaLinhaDoTempo pontoDaLinhaDoTempo' : 'linhaDaLinhaDoTempo pontoDaLinhaDoTempo semLinha'} style={{left: !imagem ? '100%' : ''}}></div>
      {imagem ? <img className='imagemItemLinhaDotempoInstitucional'  alt={`foto da fachada de ${ano}`} src={imagem}></img>:null}
    </div>
  ) : (
    <div className={'itemLinhaDoTempoInstitucional'}>
      <div className={!semLinha ? 'linhaDaLinhaDoTempo pontoDaLinhaDoTempo' : 'linhaDaLinhaDoTempo pontoDaLinhaDoTempo semLinha'}></div>
      <div className='divTextosLinhaDoTempo'>
        <h2>{ano}</h2>
        <p>{descricao}</p>
        {imagem ? <img className='imagemItemLinhaDotempoInstitucional' alt={`foto da fachada de ${ano}`} src={imagem}></img>:null}
      </div>
    </div>
  )
);

export default ItemLinhaDoTempoInstitucional;