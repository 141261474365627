import React, { useEffect, useState } from 'react';
import NotaFiscal from '../NotaFiscal';
import "./SelecionarNotaOuCupom.css";
import Cookies from 'js-cookie';
import iconPesquisa from './imgs/iconPesquisa.svg';
const HistoricoDeCompras = () => {
  const [listaDeNotasFiscais, setListaDeNotasFiscais] = useState([]);

  const pesquisarNotasFiscais = async () => {
    try {
      const token = Cookies.get('token');

      if (token) {
        const headers = new Headers({
          'Authorization': `Bearer ${token}`,
        });

        const response = await fetch(`${process.env.REACT_APP_API_URL}/notas-fiscais`, { headers });

        if (response.ok) {
          const data = await response.json();
          setListaDeNotasFiscais(data);
        } else {
          console.error('Erro na requisição:', response.statusText);
        }
      } else {
        console.error('Token não encontrado no localStorage.');
      }
    } catch (error) {
      console.error('Erro ao obter notas fiscais:', error);
    }
  };

  return (
    <div className='conteudoDaPagina' style={{ paddingTop: '2lh' }}>
      <label className='indicacaoDeCaminho'>Abrir novo protocolo ❯ Selecionar nota ou cupom</label>
      <div className='classeDivDoTituloSelecionarNota' style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
        <h1 className='tituloSelecionar'>Busque pela Nota ou Cupom</h1>
        <div className='divDoPesquisarNotaFiscal'>
          <div>
            <img src={iconPesquisa}></img>
            <input placeholder='Buscar pelo nº da Nota'></input>
          </div>
          <button onClick={pesquisarNotasFiscais}>Buscar</button>
        </div>
      </div>
      <hr></hr>
      <div style={{overflowX: 'auto', width: '100%'}}>
        <div className='divParaOverflowDoMobile'>
          <div className='cabecalhoSelecionarNotasFiscais'>
            <div style={{ width: '20%' }}>Nº do pedido</div>
            <div style={{ width: '20%' }}>Nº da NF ou Cupom</div>
            <div style={{ width: '20%' }}>Data</div>
            <div style={{ width: '20%' }} className='tituloValorTotalSelecionarNotaMobile'>Valor Total</div>
          </div>
          <hr></hr>
          <ul style={{ padding: "0" }}>
            {listaDeNotasFiscais.map((nota) => (
              <NotaFiscal key={nota.numeroNotaFiscal} nota={nota} selecionar={true} />
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default HistoricoDeCompras;
