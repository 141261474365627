import React from 'react';
import backgroundImageBannerGptw from './imgs/backgroundImageBannerVantagensGiros.jpg';
import logoGiros from './imgs/LogoGirosBannerGptw.svg';
import './BannerVantagensGiros.css';
import { Link } from 'react-router-dom';
const BannerVantagensGiros = () => {
  return (
    <div style={{marginTop: '4em'}} id="novo_GPTW" className="gptw-container">
      <img
        className="vantagensGiros-background-img"
        src={backgroundImageBannerGptw}
        alt="Background"
      />
      <div className="vantagensGiros-overlay"></div>
      <div className="gptw-content vantagensGiros-content">
        <h1>Descubra as vantagens de ser cliente Giros</h1>
        <div className='divContentBannerVantagensGiros'>
          <h2>Promoções e Sorteios</h2>
          <h2>Troca e devolução</h2>
          <h2>...e muito mais!</h2>
        </div>
        <div className='vantagensGiros-buttons'>
          <Link to={'/login'}>
            <button className="gptw-btn">Acessar Área do Cliente</button>
          </Link>
          <img src={logoGiros} alt='Logo da giros pecas'></img>
        </div>
      </div>
    </div>
  );
}
export default BannerVantagensGiros;