
import Header from '../../componentes/Header';
import React, { useEffect, useState } from 'react';
import './PageDetalharNotaFiscal.css';
import { useParams, Link } from 'react-router-dom';
import ItensNotaFiscal from '../../componentes/ItensNotaFiscal';
import BotaoDeVoltar from '../../componentes/BotaoDeVoltar';
import BarraDePesquisa from '../../componentes/BarraDePesquisa';
import imagemInterrocacao from '../../componentes/SelecionarItensNotaFiscal/imgs/ImagemInterrogacao.png';
import Cookies from 'js-cookie';
function PageDetalharNotaFiscal() {
  const { numeroDaNota } = useParams();
  const [notaFiscal, setNotaFiscal] = useState(null);
  const [filtroPesquisa, setFiltroPesquisa] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const token = Cookies.get('token');

        if (token) {
          const headers = new Headers({
            'Authorization': `Bearer ${token}`,
          });

          const response = await fetch(`${process.env.REACT_APP_API_URL}/notas-fiscais/${numeroDaNota}`, { headers });

          if (response.ok) {
            const data = await response.json();
            setNotaFiscal(data);
          } else {
            console.error('Erro na requisição:', response.statusText);
          }
        } else {
          console.error('Token não encontrado no localStorage.');
        }
      } catch (error) {
        console.error('Erro ao obter nota fiscal:', error);
      }
    };

    fetchData();
  }, [numeroDaNota]);
  const formatarData = (data) => {
    const dataFormatada = new Date(data);
    const dia = (dataFormatada.getDate() + 1).toString().padStart(2, '0');
    const mes = (dataFormatada.getMonth() + 1).toString().padStart(2, '0');
    const ano = dataFormatada.getFullYear();
    return `${dia}/${mes}/${ano}`;
  };
  return (
    <div className="contenteudo">
      <Header logado={true} />
      <div>
        <BotaoDeVoltar link="/historico" text=" ❮  Voltar" className="botaoVoltar"></BotaoDeVoltar>
        <h1 className='titulo'>Detalhes - Histórico de compras</h1>
        <div className='meioDaPagina'>
          {notaFiscal && (
            <div style={{width: '100%'}}>
              <div className='topo'>
                <h2 style={{ marginBottom: "0" }}>Nota Fiscal - nº {notaFiscal.numeroNotaFiscal}</h2>
                <div className='divDaBarraDePesquisaHistoricoDeCompras' style={{ display: "flex", justifyContent: "space-between" }}>
                  <div style={{ display: "flex" }}>
                    <p style={{ marginRight: "4lh" }}>Pedido n° {notaFiscal.id}</p>
                    <p>Data: {formatarData(notaFiscal.data)}</p>
                  </div>
                  <div className='segundoDivBarraPesquisaHistoricoCompras' style={{ display: "flex", marginBottom: "1lh" }}>
                    <BarraDePesquisa text="Pesquisar pelo código da peça" onPesquisaChange={setFiltroPesquisa} />
                    <Link to={`/detalharNotaFiscal/${numeroDaNota}`} style={{ display: "flex", width: 'fit-content', height: 'fit-content' }}>
                      <p className='solicitarTroca'>
                        <img src={imagemInterrocacao} alt='Imagem Interrogação' className='imagemInterrogacao'></img>
                        Solicitar Troca
                      </p>
                    </Link>
                  </div>
                </div>
              </div>
              <div className='divDaTabelaDetalharNotaFiscalParaMobile'>
                <table className='tabela'>
                  <thead>
                    <tr style={{ color: "#3E3E3E" }}>
                      <th>Código Peça</th>
                      <th>Qtd.</th>
                      <th>CST</th>
                      <th>CFOP</th>
                      <th>Valor Sub. Trib.</th>
                      <th>IPI</th>
                      <th>Aliq. IPI</th>
                      <th>Base ICMS</th>
                      <th>ICMS</th>
                      <th>Aliq. ICMS</th>
                      <th>FCP ST</th>
                      <th>Valor Uni.</th>
                      <th className='tirarPaddingRightParaMobile' style={{ paddingRight: "25vh" }}>Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {notaFiscal.itensFiscais
                      .filter(item => item.codigoDaPeca.toLowerCase().includes(filtroPesquisa.toLowerCase()))
                      .map((item) => (
                        <ItensNotaFiscal key={item.codigoDaPeca} item={item} selecionar={false} espacamento={true} />
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
export default PageDetalharNotaFiscal;