import React, { useState, useEffect } from 'react';

const ChatOctadesk = () => {
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const injectScript = () => {
      // Verifica se o script já foi injetado
      if (!document.querySelector(`script[src="https://cdn.octadesk.com/embed.js"]`)) {
        (function (o, c, t, a, d, e, s, k) {
          o.octadesk = o.octadesk || {};
          o.octadesk.chatOptions = {
            subDomain: a,
            showButton: d === "true",
            openOnMessage: e === "true",
            hide: s === "true",
          };
          const bd = c.getElementsByTagName("body")[0];
          const sc = c.createElement("script");
          sc.async = 1;
          sc.src = t;
          bd.appendChild(sc);
        })(window, document, "https://cdn.octadesk.com/embed.js", "o193341-21b", true, false, false);
      }
    };

    injectScript();
  }, []); // Executa apenas uma vez quando o componente for montado

  useEffect(() => {
    const fetchUserData = async () => {
      const response = await fetch("/api/user/info");
      if (response.ok && response.headers.get('content-type')?.includes('application/json')) {
        const data = await response.json();
        setUserData(data);
      }
    };

    fetchUserData();

    const onChatReady = () => {
      if (window.octadesk && window.octadesk.chat && userData) {
        // eslint-disable-next-line no-undef
        octadesk.chat.login({
          name: userData.name,
          email: userData.email,
        });
      }
    };

    window.addEventListener('onOctaChatReady', onChatReady);

    return () => {
      window.removeEventListener('onOctaChatReady', onChatReady);
    };
  }, [userData]);

  return null;
};

export default ChatOctadesk;