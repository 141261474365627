import React from 'react';
import './BarraDePesquisa.css';
import lupaImage from './lupa.png'

const BarraDePesquisa = ({ text, onPesquisaChange }) => {
    return (
       <div className="pesquisar">
         <img src={lupaImage} alt="Imagem de lupa" className= "imagemDeLupa"/>
         <input
           placeholder={text}
           className='inputDeTextoDaPesquisa'
           onChange={(e) => onPesquisaChange(e.target.value)}
         />
       </div>
    );
   };
export default BarraDePesquisa;